import { PyroLog } from "@pyrologic/pyrologjs";

const PL = PyroLog.getInstance();

const _internal_name = '_logging_manager';

// very basic default configuration
const basic_config = [
    PL.createConfigItem(PL.defaultName, 'DEBUG', true),
    PL.createConfigItem('key.GlbKeyLis', 'INFO', false),
    PL.createConfigItem('key.KeyHdl', 'INFO', false),
    PL.createConfigItem('utils.UIRefresh', 'DEBUG', false),
    PL.createConfigItem(_internal_name, 'OFF', false)
];

export default class Logging {

    constructor() {
        PL.applyConfiguration(basic_config);
        this._logger = PL.getLogger(_internal_name);
    }

    /**
     * @returns {Logging} the singleton instance
     */
    static getInstance() {
        return singleton;
    }

    /**
     * @returns {PyroLog} the PyroLog main instance
     */
    static getPyroLog() {
        return PL;
    }

	/**
	 * applies the logger configuration
	 * @param {*} parameter object
	 */
    applyConfiguration(args) {
        const global = args.global || {};
        const loggers = args.loggers || [];
        PL.setGlobalOptions(global);
        if ( loggers.length > 0 ) {
            const cfgs = [];
            let internal_found = false;
            loggers.forEach((l) => {
                cfgs.push(PL.createConfigItem(l.name, l.level, l.writeFnc));
                if ( !internal_found && (_internal_name === l.name) ) {
                    internal_found = true;
                }
            });
            if ( !internal_found ) {
                cfgs.push(PL.createConfigItem(_internal_name, 'OFF', false));
            }
            PL.applyConfiguration(cfgs);
        }
    }

    /**
     * retrieves a logger
     * @param {String} name logger name
     * @returns {Logger} the corresponding logger
     */
    getLogger(name) {
        const logger = this._logger;
        if ( logger.isTraceEnabled() ) {
            logger.trace(`Retrieving logger "${name}".`);
        }
        return PL.getLogger(name);
    }

    /**
     * temporarily suspends all loggers
     */
    suspend() {
        this._setSuspended(true);
    }

    /**
     * resumes normal logging
     */
    resume() {
        this._setSuspended(false);
    }

    /**
     * changes the global "suspended" option
     * @param {Boolean} suspended new value of the "suspended" option
     */
    _setSuspended(suspended) {
        PL.setGlobalOptions({ suspended: suspended });
    }
}

const singleton = new Logging();