import KeyHdl from '../../key/KeyHdl';
import HtmHelper from '../../utils/HtmHelper';
import Validator from '../../utils/Validator';
import ItmMgr from '../ItmMgr';
import Utils from '../../utils/Utils';
import DomEventHelper from '../../utils/DomEventHelper';

/**
 * copy to clipboard confirmation dialog class
 */
export default class CpyClpCfd {

	/**
	 * constructs a new instance
	 * @param {String} data data string to be copied to the clipboard
	 * @param {String} ico icon descriptor
	 * @param {*} par additional parameters
	 */
	constructor(data, ico, par) {
		const host = document.createElement('div');
		this.hostElm = host;
		this.dataElm = null;
		this.copyMsg = par.msg || '';
		this.keysBlocked = KeyHdl.getInstance().isBlocked();
		this._render(host, data, ico, par);
	}

	/**
	 * destructor method
	 */
	destroy() {
		HtmHelper.rmvDomElm(this.hostElm);
		KeyHdl.getInstance().blockGlobalKeyHandling(this.keysBlocked);
		delete this.keysBlocked;
		delete this.copyMsg;
		delete this.dataElm;
		delete this.hostElm;
	}

	/**
	 * shows the dialog
	 */
	show() {
		if ( this.hostElm ) {
			this.keysBlocked = KeyHdl.getInstance().isBlocked();
			KeyHdl.getInstance().blockGlobalKeyHandling(true);
			document.body.appendChild(this.hostElm);
			if ( this.dataElm ) {
				this.dataElm.focus();
				this.dataElm.select();
				this.dataElm.scrollLeft = 0;
			}
		}
	}

	/**
	 * renders the dialog
	 * @param {HTMLElement} host the host (background) element
	 * @param {String} data data string to be copied to the clipboard
	 * @param {String} ico icon descriptor
	 * @param {*} par additional parameters
	 */
	_render(host, data, ico, par) {
		const im = ItmMgr.getInst();

		host.className = 'dlghost';

		const dlg_wnd = document.createElement('div'); 
		dlg_wnd.className = 'dlgwindow';

		const dlg_body = document.createElement('div');
		dlg_body.className = 'dlgbody';

		const ctt_frame = document.createElement('div');
		ctt_frame.className = 'dlgcttframe';
		const hdr_frame = document.createElement('div');
		hdr_frame.className = 'dlghdrframe';

		if ( ico ) {
			const hdr_icon = document.createElement('div');
			hdr_icon.className = 'dlghdricon';
			hdr_icon.appendChild(im.creImg(ico, 17, false));
			hdr_frame.appendChild(hdr_icon);
		}
		if ( Validator.isString(par.ttl) ) {
			const ttl = document.createElement('div');
			ttl.className = 'dlghdrtitle';
			ttl.innerHTML = Utils.escHtml(par.ttl);
			hdr_frame.appendChild(ttl);
		}
		const body_frame = document.createElement('div');
		body_frame.className = 'dlgbodyframe';

		if ( Validator.isString(par.dsc) ) {
			const dsc = document.createElement('div');
			dsc.className = 'dlgdsctext';
			dsc.innerHTML = Utils.escHtml(par.dsc);
			body_frame.appendChild(dsc);
		}

		const inp = document.createElement('input');
		inp.className = 'dlgdata';
		inp.readOnly = true;
		inp.value = '' + data;
		body_frame.appendChild(inp);
		this.dataElm = inp;

		ctt_frame.appendChild(hdr_frame);
		ctt_frame.appendChild(body_frame);

		const btn_frame = document.createElement('div');
		btn_frame.className = 'dlgbtnframe';

		const btn_close = document.createElement('div');
		btn_close.classList.add('dlgbtn');
		btn_close.classList.add('dlgclosebtn');
		btn_close.innerHTML = '<i class="fp fp-close-2"></i>';
		btn_frame.appendChild(btn_close);

		const filler = document.createElement('div');
		filler.className = 'filler';
		btn_frame.appendChild(filler);

		const btn_copy = document.createElement('div');
		btn_copy.classList.add('dlgbtn');
		btn_copy.classList.add('dlgcopybtn');
		btn_copy.innerHTML = Utils.escHtml(par.btx || 'Copy');
		if ( Validator.isString(par.btp) ) {
			btn_copy.title = par.btp;
		}
		btn_frame.appendChild(btn_copy);

		dlg_body.appendChild(ctt_frame)
		dlg_body.appendChild(btn_frame);
		dlg_wnd.appendChild(dlg_body);

		host.appendChild(dlg_wnd);

		const self = this;
		host.addEventListener('click', (e) => {
			self._onHostClick(e);
		});
		host.addEventListener('keyup', (e) => {
			self._onKeyStroke(e);
		}, true);
		btn_close.addEventListener('click', () => {
			self._onClose();
		})
		btn_copy.addEventListener('click', () => {
			self._onCopy();
		});
	}

	/**
	 * global "click" listener
	 * @param {MouseEvent} e the "click" event
	 */
	_onHostClick(e) {
		if ( (e.button === 0) && !HtmHelper.isChildOf(e.target, this.hostElm) ) {
			DomEventHelper.stopEvent(e);
		}
		if ( (e.button === 0) && (e.target === this.hostElm) ) {
			this._onClose();
		}
	}

	/**
	 * closes the dialog
	 */
	_onClose() {
		const self = this;
		setTimeout(() => {
			self.destroy();
		}, 1);
	}

	/**
	 * copies the text to the clipboard, creates a confirmation message and closes the dialog
	 */
	_onCopy() {
		if ( this.dataElm ) {
			this.dataElm.focus();
			this.dataElm.select();
			this.dataElm.scrollLeft = 0;
			// old style and deprectaed but still supported :-)
			document.execCommand('copy');
			if ( Validator.isString(this.copyMsg) ) {
				if ( pisasales.cliCbkWdg ) {
					pisasales.cliCbkWdg.shwFloMsg( { txt: this.copyMsg, ico: null, fntico: '<i class="fa fa-info"></i>' } );
				}
			}
		}
		this._onClose();
	}

	/**
	 * handles key strokes
	 * @param {KeyboardEvent} e the keyboard event
	 */
	_onKeyStroke(e) {
		let cls = false;
		switch ( e.key ) {
			case 'Enter':
				this._onCopy();
				cls = true;
				break;
			case 'Escape':
				cls = true;
				break;
			default:
				break;
		}
		if ( cls ) {
			this._onClose();
		}
	}
};

console.debug('gui/misc/CpyClpCfd.js loaded.');