import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import DndCso from '../../gui/misc/DndCso';

/**
 * PsaImg custom widget class
 */
export default class PsaImg {

    /**
     * constructs a new instance
     * @param {*} properties initialization arguments
     */
    constructor( properties ) {
        this._psa = PSA.getInst();
        this._psa.bindAll( this, ['layout', 'onReady', 'onSend', 'onRender', '_onDrop', '_onDragOver'] );
        let parent = rap.getObject( properties.parent );
        this.parent = parent;
        this.element = document.createElement( 'div' );
        this._makeDroppable( this.element, true );
        this.element.style.textAlign = 'center';
        this.parent.append( this.element );
        this.parent.addListener( "Resize", this.layout );
        this.tmpIcoDsc = null;
        this.tmpPrpRszImg = null;
        this.tmpJstImg = null;
        this.ready = false;
        this.imgElm = null;
        this.shpDiv = null;
        this.isJst = false;
        this.rndShp = false;
        this.hyperLink = false;
        // custom widget data
        this.cwd = parent.getData( 'pisasales.CSTPRP.CWD' );
        // use client-side XHR requests for URL retrieval if this variable provides a URL
        this.clientSideImageXHR = this.cwd.clientSideImageXHR;
        // link drop URL, used for direct File/Blob uploads
        this.linkDropUrl = this.cwd.linkDropUrl;
        rap.on( "render", this.onRender );
    }

    destroy() {
        if ( this.element ) {
            this._stripDroppable( this.element );
            if ( this.element.parentNode ) {
                this.element.parentNode.removeChild( this.element );
            }
        }
        delete this.hyperLink;
        delete this.linkDropUrl;
        delete this.clientSideImageXHR;
        delete this.cwd;
        delete this.tmpIcoDsc;
        delete this.tmpPrpRszImg;
        delete this.tmpJstImg;
        delete this.parent;
        delete this.element;
    }

    onReady() {
        this.ready = true;
        if ( this.tmpIcoDsc ) {
            this.setIcoDsc( this.tmpIcoDsc );
            this.tmpIcoDsc = null;
        } else if ( this.tmpPrpRszImg ) {
            this.setprpRszImg( this.tmpPrpRszImg );
            this.tmpPrpRszImg = null;
        } else if ( this.tmpJstImg ) {
            this.setJstImg( this.tmpJstImg );
            this.tmpJstImg = null;
        }
        // update the layout
        this.layout();
    }

    onRender() {
        if ( this.element && this.element.parentNode ) {
            rap.off( "render", this.onRender );
            this.onReady();
        }
    }

    onSend() {
        // do nothing so far...
    }

    layout() {
        if ( this.ready ) {
            let area = this.parent.getClientArea();
            this.element.style.left = area[0] + "px";
            this.element.style.top = area[1] + "px";
            const width = '' + area[2] + 'px';
            const height = '' + area[3] + 'px';
            this.element.style.width = width;
            this.element.style.height = height;
            if ( this.imgElm && this.isJst === false ) {
                this.imgElm.style.maxWidth = width;
                this.imgElm.style.maxHeight = height;
                this.imgElm.setAttribute( "width", "auto" );
            } else if ( this.shpDiv && ( this.isJst === true ) ) {
                const mgr = ItmMgr.getInst();
                let isz = mgr.getElementSize( this.shpDiv );
                let hgt = area[3];
                let top = Math.max( ( hgt - isz.height ) / 2, 0 );
                this.shpDiv.style.top = '' + top + 'px';
                this.shpDiv.style.position = 'absolute';
            }
        }
    }

    setPar() {
        // empty so far...
    }

    /**
     * removes the image from the HTML/DOM structure
     */
    rmvImg() {
        if ( this.element ) {
            if ( this.imgElm ) {
                for ( let i = 0; i < this.element.childElementCount; i++ ) {
                    this.element.removeChild( this.element.children[i] );
                }
                this.imgElm = null;
            }
            this.shpDiv = null;
        }
    }

    /**
     * changes the "hyper link" flag
     * @param {Boolean} args new value of the "hyper link" flag
     */
    setHyperLink(args) {
        this.hyperLink = !!args;
        if ( !this.hyperLink && this.imgElm ) {
            this.imgElm.style.removeProperty('cursor');
        }
    }

    /**
     * @returns {Boolean} true if this instance has the "hyper link" style; false otherwise
     */
    _hasLinkStyle() {
        return this.hyperLink;
    }

    /**
     * adds the listeners to the image element
     */
    _addImgLis( elm ) {
        const self = this;
        const img = elm;
        img.onmouseover = function () {
            if ( self._hasLinkStyle() ) {
                img.style.cursor = "pointer";
            }
        };
        img.onmouseout = function () {
            if ( self._hasLinkStyle() ) {
                img.style.removeProperty( "cursor" );
            }
        };
        img.onclick = function ( evt ) {
            if ( self._hasLinkStyle() ) {
                const par = {};
                par.btn = evt.button;
                par.x = evt.x;
                par.y = evt.y;
                self._nfySrv( 'clicked', par );
            }
        };
    }

    _nfySrv( code, par ) {
        if ( this.ready ) {
            const param = {};
            param.cod = code;
            param.par = par;
            rap.getRemoteObject( this ).notify( "PSA_IMG_NFY", param );
        }
    }

    _getImgPar( args ) {
        const ret = { path: '', width: 0, height: 0 };
        const img = args.img;
        if ( img ) {
            if ( args.svg ) {
                // a SVG image
                const url = img.url || false;
                const svg = img.svg || '';
                const pfx = this._psa.isStr( svg ) ? ( url ? '' : 'data:image/svg+xml;base64,' ) : '';
                ret.path = pfx + svg;
                ret.width = img.wdt || 0;
                ret.height = img.hgt || 0;
            } else {
                // a common SWT image (bitmap)
                ret.path = img[0] || '';
                ret.width = img[1] || 0;
                ret.height = img[2] || 0;
            }
        }
        return ret;
    }

    /**
     * draws a justified image via image tag.
     */
    setJstImg( args ) {
        if ( !this.ready ) {
            this._clrTmpImgs();
            this.tmpJstImg = args;
            return;
        }
        const img = this._getImgPar( args );
        const imgPth = img.path || '';
        const imgWdt = img.width || 0;
        const imgHgh = img.height || 0;
        let argsmxw = args.maxwidth || 0;
        let argsmxh = args.maxheight || 0;
        if ( this.parent && ( ( argsmxw <= 0 ) || ( argsmxh <= 0 ) ) ) {
            const area = this.parent.getClientArea();
            argsmxw = area[2];
            argsmxh = area[3];
        }
        const maximgWdt = argsmxw;
        const maximgHgh = argsmxh;
        this.rmvImg();
        if ( imgPth && imgWdt && imgHgh ) {
            let new_img = document.createElement( "img" );
            new_img.setAttribute( "src", imgPth );
            new_img.setAttribute( "width", "" + imgWdt + "px" );
            new_img.style.maxWidth = "" + maximgWdt + "px";
            new_img.setAttribute( "height", "" + imgHgh + "px" );
            new_img.style.maxHeight = "" + maximgHgh + "px";
            new_img.style.marginTop = "" + ( maximgHgh - imgHgh ) / 2 + "px";
            this._addImgLis( new_img );
            this.imgElm = new_img;
            if ( args.rndShp ) {
                this.rndShp = args.rndShp;
                let shapeDiv = document.createElement( "div" );
                shapeDiv.style.borderRadius = "50%";
                shapeDiv.setAttribute( "width", ( maximgWdt ? maximgWdt : imgWdt ) + "px" );
                shapeDiv.setAttribute( "height", ( maximgWdt ? maximgWdt : imgWdt ) + "px" );
                shapeDiv.style.width = ( maximgWdt ? maximgWdt : imgWdt ) + "px";
                shapeDiv.style.height = ( maximgWdt ? maximgWdt : imgWdt ) + "px";
                shapeDiv.style.overflow = "hidden";
                /* make it all the same with so it will be round not oval */
                this.imgElm.style.verticalAlign = "middle";

                shapeDiv.appendChild( this.imgElm );
                this.element.appendChild( shapeDiv );
                this.shpDiv = shapeDiv;
            } else {
                this.element.appendChild( new_img );
            }

            this.isJst = true;
            this._makeDroppable( this.imgElm );
        }
    }

    /**
     * draws a proportionally resized image via image tag.
     */
    setprpRszImg( args ) {
        if ( !this.ready ) {
            this._clrTmpImgs();
            this.tmpPrpRszImg = args;
            return;
        }
        const img = this._getImgPar( args );
        const imgPth = img.path || '';
        const imgWdt = img.width || 0;
        const imgHgh = img.height || 0;
        const maximgWdt = args.maxwidth || 0;
        const maximgHgh = args.maxheight || 0;
        const isFitHor = args.fitHor || false;
        const isFitVrt = args.fitVert || false;
        this.rmvImg();
        if ( imgPth && imgWdt && imgHgh ) {
            let new_img = document.createElement( "img" );
            new_img.setAttribute( "src", imgPth );

            if ( ( isFitHor === true && isFitVrt === true ) || ( isFitHor === false && isFitVrt === false ) ) {
                new_img.style.width = "auto";
                new_img.style.height = "auto";
                /* Check if image dimension is suitable to container */
                if ( parseInt( this.element.style.height, 10 ) > maximgHgh && parseInt( this.element.style.width, 10 ) > maximgWdt ) {
                    /* set the server-provided max-width & -height */
                    new_img.style.maxWidth = "" + maximgWdt + "px";
                    new_img.style.maxHeight = "" + maximgHgh + "px";
                } else {
                    /* set the container size for max-width & -height */
                    new_img.style.maxWidth = this.element.style.width;
                    new_img.style.maxHeight = this.element.style.height;
                }
            } else if ( isFitHor === true && isFitVrt === false ) {
                new_img.style.width = "100%";
                new_img.style.height = "auto";
            } else if ( isFitHor === false && isFitVrt === true ) {
                new_img.style.width = "auto";
                new_img.style.height = "100%";
            }
            this._addImgLis( new_img );

            if ( args.rndShp ) {
                this.rndShp = args.rndShp;
                let shapeDiv = document.createElement( "div" );
                shapeDiv.style.borderRadius = "50%";
                shapeDiv.style.overflow = "hidden";
                let dim = ( maximgWdt < maximgHgh ) ? maximgWdt : maximgHgh;
                if ( !dim ) {
                    const tmpHgt = parseInt( this.element.parentElement.style.height, 10 );
                    const tmpWdt = parseInt( this.element.parentElement.style.width, 10 );
                    dim = ( tmpWdt < tmpHgt ) ? tmpWdt : tmpHgt;
                }
                shapeDiv.style.width = dim + "px";
                shapeDiv.style.height = dim + "px";
                new_img.style.position = "relative";

                // landscape picture horizontal centering
                if ( imgWdt >= imgHgh ) {
                    new_img.style.width = "auto";
                    new_img.style.maxWidth = "";
                    let height = ( ( dim > imgHgh ) ? imgHgh : dim );
                    new_img.style.height = height + "px";
                    new_img.style.left = "50%";
                    new_img.style.marginLeft = -dim + "px";
                } else {
                    // portrait
                    if ( imgHgh > dim ) {
                        new_img.style.top = "-2%";
                    }
                    new_img.style.left = "-1px";
                    new_img.style.maxWidth = ( dim + 2 ) + "px";
                    new_img.style.width = ( dim + 2 ) + "px";
                    // new_img.setAttribute("width", (maximgWdt + 2) + "px");
                    new_img.style.height = "auto";
                    new_img.style.maxHeight = "";
                }

                shapeDiv.appendChild( new_img );
                this.element.appendChild( shapeDiv );
                this.shpDiv = shapeDiv;
                this.isJst = true;
            } else {
                this.element.appendChild( new_img );
                this.isJst = false;
                if ( isFitVrt === true ) {
                    // th: set vertical alignment
                    new_img.style.verticalAlign = 'middle';
                }
            }
            this.imgElm = new_img;
            this._makeDroppable( this.imgElm );
        }
    }

	/**
	 * forces a layout update
	 */
     forceLayout() {
        if ( this.ready ) {
            this.layout();
        }
    }

    /**
     * creates a font based icon
     */
    setIcoDsc( args ) {
        if ( !this.ready ) {
            this._clrTmpImgs();
            this.tmpIcoDsc = args;
            return;
        }
        this.rmvImg();
        if ( this.element ) {
            const dsc = args.dsc;
            if ( dsc ) {
                const mgr = ItmMgr.getInst();
                const ico = mgr.creDscIco( dsc, null, true );
                if ( ico ) {
                    const div = document.createElement( 'div' );
                    div.className = 'icoDscDiv';
                    div.style.display = 'flex';
                    div.style.alignItems = 'center';
                    const isz = args.siz;
                    if ( typeof isz === 'number' ) {
                        ico.style.fontSize = '' + mgr.getFntSiz( isz ) + 'px';
                    }
                    div.appendChild( ico );
                    this._addImgLis( div );
                    this.imgElm = div;
                    this.element.appendChild( div );
                    this.isJst = true;
                    this._makeDroppable( ico );
                    this._makeDroppable( div );
                }
            }
        }
    }

    /**
     * Will clear all temporarily stored images.
     */
    _clrTmpImgs() {
        this.tmpIcoDsc = null;
        this.tmpJstImg = null;
        this.tmpPrpRszImg = null;
    }

    /**
     * Drop handling.
     * @param {DropEvent} event the drop event
     */
    _onDrop( event ) {
        rwt.event.EventHandlerUtil.stopDomEvent( event );
        // ok, it will get very tricky very quickly, this is a very simplified
    
        const util = new DndCso();
        const data = util.extractSingleImageData( event.dataTransfer );
    
        if ( data ) {
            if ( data.type === 'web-uri' && this.clientSideImageXHR ) {
                // ok, go get the blob asynchronously
                this._triggerClientSideBlobRetrieval( data );
            } else {
                this._sendBlobToServer( data );
            }
        }
    }

    /**
     * Drag over handling.
     * @param {DragEvent} event the drag event
     */
    _onDragOver( event ) {
        if ( event.preventDefault ) {
            event.preventDefault();
        }
        if ( event.stopPropagation ) {
            event.stopPropagation();
        }
        event.dataTransfer.dropEffect = 'link';
        return false;
    }

    /**
     * Adds a specific class to the element which indicates to the DnD customization
     * that the target element supports its own drop handling.
     * @param {Element} target the target element
     * @param {Boolean} attachListeners flag indicates whether listener should be attached
     */
    _makeDroppable( target, attachListeners ) {
        target.classList.add( DndCso.getCssClass() );
        if ( !!attachListeners ) {
            target.addEventListener( 'drop', this._onDrop );
            target.addEventListener( 'dragover', this._onDragOver );
        }
    }

    /**
     * Removes the drop listeners from the target element.
     */
    _stripDroppable( target ) {
        target.removeEventListener( 'drop', this._onDrop );
        target.removeEventListener( 'dragover', this._onDragOver );
    }

    /**
     * Sends a BLOB containing the blobData to the server.
     * @param {Object} data an object containing URL and URL type
     */
    _sendBlobToServer( data ) {
        const formData = new FormData();
        // add the web locations and encoded images
        const blob = new Blob( [JSON.stringify( data )], {
            type: 'application/json'
        } );
        formData.append( 'blob0', blob, 'blob0' );
    
        // trigger the request
        const xhr = rwt.remote.Request.createXHR();
        xhr.open( 'POST', this.linkDropUrl );
        xhr.send( formData );
    }

    /**
     * Triggers client side BLOB retrieval of the web-uri.
     * @param {Object} data contains the web resource to be retrieved
     */
    _triggerClientSideBlobRetrieval( data ) {
        const self = this;
        const payload = 'url=' + btoa( data.url ) + '&outputFormat=base64';
        const request = new XMLHttpRequest();
        request.onload = function () {
            if ( ( request.status === 200 ) && ( request.responseText ) ) {
                data.url = request.responseText;
                data.type = 'base64';
                self._sendBlobToServer.apply( self, [data] );
            }
        };
        request.open( 'POST', this.clientSideImageXHR, true );
        request.setRequestHeader( "Content-Type", "application/x-www-form-urlencoded" );
        request.send( payload );
    }

    /** register custom widget type */
    static register() {
        console.debug( 'Registering custom widget PsaImg.' );
        rap.registerTypeHandler( "psawidget.PsaImg", {
            factory: function ( properties ) {
                return new PsaImg( properties );
            },                            
            destructor: "destroy",
            properties: [ 'hyperLink' ],
            methods: ["setPar", "setJstImg", "setprpRszImg", "setIcoDsc", "rmvImg", "forceLayout"],
            events: ["PSA_IMG_NFY"]
        } );                            
    }                            
}                            

console.debug( 'widgets/psaimg/PsaImg.js loaded.' );