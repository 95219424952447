import Logging from "../utils/logging";
import Validator from "../utils/Validator";
import Base from "./base";

export default class LoggingBase extends Base {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super();
        if ( !Validator.isString(ln) ) {
            throw new Error('No logger name specified!');
        }
        this._logger = Logging.getInstance().getLogger(ln);
        this._logger.setFncOffset(1);
    }

    /**
     * @returns {PyroLog} the PyroLog main instance
     */
    static getPyroLog() {
        return Logging.getPyroLog();
    }

    /**
     * @override
     */
    doDestroy() {
        delete this._logger;
        super.doDestroy();
    }

    /**
     * @returns {Logger} the logger
     */
    get logger() {
        this.checkAlive();
        return this._logger;
    }

    /**
     * @returns {Boolean} true if DEBUG level logging is enabled; false otherwise
     */
    isDebugEnabled() {
        return this.alive && this.logger.isDebugEnabled();
    }

    /**
     * @returns {Boolean} true if TRACE level logging is enabled; false otherwise
     */
    isTraceEnabled() {
        return this.alive && this.logger.isTraceEnabled();
    }

	/**
	 * writes a trace log message
	 * @param {*} msg trace message
	 */
    trace(...msg) {
        this.checkAlive();
		this._logger.trace(`{${this.iid}}`, ...msg);
    }

	/**
	 * writes a debug log message
	 * @param {*} msg log message
	 */
    log(...msg) {
        this.checkAlive();
		this._logger.debug(`{${this.iid}}`, ...msg);
	}

	/**
	 * writes a warning message
	 * @param {*} msg the warning message
	 */
	warn(...msg) {
        this.checkAlive();
		this._logger.warn(`{${this.iid}}`, ...msg);
	}

	/**
	 * writes an error message
	 * @param {*} msg the error message
	 */
    error(...msg) {
        this.checkAlive();
		this._logger.error(`{${this.iid}}`, ...msg);
	}
}