import RapWidget from "./RapWidget";
import Validator from "./Validator";

/**
 * generic utility methods
 */
export default class Utils {

	/**
	 * creates a function that is bound to a specific context (i.e. an instance)
	 * @param {Object} context the target object
	 * @param {Function} method the method to be bound to the specified context
	 * @returns {Function} a function that binds the specified method to the specified context
	 */
	static bind( context, method ) {
		return function() {
			return method.apply( context, arguments );
		};
	}

	/**
	 * binds all specified methods to the given instance
	 * @param {Object} context the target object
	 * @param {String[]} methodNames the names of the methods to be bound to the specified context
	 */
	static bindAll( context, methodNames ) {
		for ( let i = 0; i < methodNames.length; ++i ) {
			let method = context[ methodNames[ i ] ];
			context[ methodNames[ i ] ] = this.bind( context, method );
		}
	}


	/**
	 * calls function 'f' for each own property of object 'o'; * the signature of 'f' is f(name, value)
	 * @param {Object} o the target object
	 * @param {Function} f the function to be called
	 */
    static forEachProp( o, f ) {
		Object.getOwnPropertyNames( o ).forEach( function( p ) {
			if ( typeof o[ p ] !== 'function' ) {
				f( p, o[ p ] );
			}
		} );
	}

	/**
	 * escapes (quotes) HTML code so that it will be displayed as literal text
	 * @param {String} s the source string
	 * @returns {String} a probably string with quoted angle brackets
	 */
	static escHtml( s ) {
		let res = s || '';
		if ( Validator.isString( res ) ) {
			const RGX_OPN = /</g;
			const RGX_CLS = />/g;
			const RPL_OPN = '&lt;';
			const RPL_CLS = '&gt;';
			res = res.replace( RGX_OPN, RPL_OPN ).replace( RGX_CLS, RPL_CLS );
		}
		return res;
	}

	/**
	 * Debounce function, inspired by (copied from) David Walsh.
	 * See here (https://davidwalsh.name/javascript-debounce-function).
	 * Lodash.debounce was too long.
	 * @param {Function} func the function to debounce
	 * @param {Number} wait the number of milliseconds to wait for repeated calls before the function is triggered
	 * @param {Boolean} immediate triggers the function on the 'leading' call, instead of the 'tail' end of the series of calls
	 * @return {Function} the debounced function
	 */
	static debounce( func, wait, immediate ) {
		let timeout = null;
		return function() {
			const context = this;
			const args = arguments;
			const later = () => {
				timeout = null;
				if ( !immediate ) {
					func.apply( context, args );
				}
			};
			const callNow = immediate && !timeout;
			clearTimeout( timeout );
			timeout = setTimeout( later, wait );
			if ( callNow ) {
				func.apply( context, args );
			}
		};
	}

	/**
	 * retrieves a RAP widget
	 * @param {String} idw widget ID
	 * @returns {RapWidget} the RAP widget or null if not found
	 */
	static getRapWidget(idw) {
		const wdg = rwt.remote.ObjectRegistry.getObject(idw);
		if ( wdg ) {
			return new RapWidget(idw, wdg);
		}
		return null;
	}

}