import Validator from '../../../utils/Validator';
import Base from '../../../base/base';
import CellProp from './CellProp';

/** empty content constant */
const EMPTY_CTT = Object.freeze( { html: false, rawText: '', text: '', prop: {} } );

/**
 * XTW cell content
 */
export default class CellCtt extends Base {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 */
	constructor( json ) {
		super();
		this.text = json.text || '';
		this.rawText = json.rawText || this.text;
		this.html = !!json.html;
		// default cell access mode is writable (3)
		const cellAccessMode = Number( json.acc );
		this.acc = Validator.isPositiveInteger( cellAccessMode ) ? cellAccessMode : 3;
		this.prop = new CellProp( json.prop || {} );
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
		this.prop.destroy();
		delete this.rawText;
		delete this.text;
		delete this.html;
		delete this.prop;
		delete this.acc;
	}

	static get EMPTY_CONTENT() {
		return Object.assign( {}, EMPTY_CTT );
	}
}

export { EMPTY_CTT };
