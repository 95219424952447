import LoggingBase from "../../../../base/loggingbase";
import IBody from "../../ifcs/IBody";
import PSA from "../../../../psa";
import DomEventHelper from "../../../../utils/DomEventHelper";

/**
 * key event handler for the table body
 */
export default class XtwBodyKeyEventHandler extends LoggingBase {

    /**
     * constructs a new instance
     * @param {IBody} xtb table body
     */
    constructor(xtb) {
        super('widgets.xtw.XtwBodyKeyEventHandler');
        Object.defineProperty(this, '_xtwBody', {
            value: xtb,
            writable: false,
            configurable: false
        });
        this._psa = PSA.getInst();
        // bind event handlers
        this.onKeyDown = this._psa.bind(this, this._onKeyDown);
        this.onKeyUp = this._psa.bind(this, this._onKeyUp);
        // add event handlers
        const tbe = this.rapElement;
        tbe.addEventListener('keydown', this.onKeyDown);
        tbe.addEventListener('keyup', this.onKeyUp);
        // status flags
        this._keyDownPending = false;
        this._keyUpPending = false;
    }

	/**
	 * destructor method
     * @override
	 */
     doDestroy() {
        const tbe = this.rapElement;
        if ( tbe instanceof HTMLElement ) {
            tbe.removeEventListener('keydown', this.onKeyDown);
            tbe.removeEventListener('keyup', this.onKeyDown);
        }
        delete this.onKeyDown;
        delete this.onKeyUp;
		super.doDestroy();
	}

    /**
     * @returns {IBody} the table body
     */
    get xtwBody() {
        return this._xtwBody;
    }

    /**
     * @returns {HTMLElement} the RAP DOM element of the table body
     */
    get rapElement() {
        return this.xtwBody.element.parentElement;
    }

    /**
     * handles 'keydown' events
     * @param {KeyboardEvent} e the keyboard event
     */
    _onKeyDown(e) {
        this.onKeyEvent(e, true);
    }

    /**
     * handles 'keyup' events
     * @param {KeyboardEvent} e the keyboard event
     */
    _onKeyUp(e) {
        this.onKeyEvent(e, false);
    }

    /**
     * handles a keyboard event
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} press flag whether the key is pressed ('keydown') or released ('keyup')
     */
    onKeyEvent(e, press) {
        if ( DomEventHelper.isProcessed(e) ) {
            return;
        }
        const shift = e.shiftKey;
        const ctrl = e.ctrlKey;
        const tab = DomEventHelper.isTabKey(e);
        let arrow = false;
        let page = false;
        let dir = false;                // direction flag: false=downwards; true=upwards
        if ( !tab ) {
            if ( DomEventHelper.isArrowDown(e) ) {
                arrow = true;
            }
            else if ( DomEventHelper.isArrowUp(e) ) {
                arrow = true;
                dir = true;
            }
            else if ( DomEventHelper.isPageDown(e) ) {
                page = true;
            }
            else if ( DomEventHelper.isPageUp(e) ) {
                page = true;
                dir = true;
            }
        }
        if ( tab || arrow || page ) {
            this.log(`"${press ? 'keydown' : 'keyup'}" - tab=${tab}; arrow=${arrow}; page=${page}; direction=${dir ? 'up' : 'down'}.`);
            try {
                if ( press ) {
                    if ( !this._keyDownPending && !this._keyUpPending ) {
                        this._keyDownPending = true;
                        this._handleKeyDown(e, shift, ctrl, tab, arrow, page, dir);
                    }
                }
                else {
                    if ( !this._keyUpPending ) {
                        this._keyUpPending = true;
                        this._handleKeyUp(e, shift, ctrl, tab, arrow, page, dir);
                    }
                }
            } finally {
                if ( true !== e.__psa_leave_it ) {
                    DomEventHelper.stopEvent(e);
                }
            }
        }
    }
    
    /**
     * handles relevant "keydown" events
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     * @param {Boolean} tab "[Tab] key" flag
     * @param {Boolean} arrow "arrow key" flag
     * @param {Boolean} page "page up/down" flag
     * @param {Boolean} dir direction flag
     */
    _handleKeyDown(e, shift, ctrl, tab, arrow, page, dir) {
        try {
            // handle "keydown"
            if ( tab ) {
                this.xtwBody.onTabKey(true, e, shift, ctrl);
            } else {
                this.xtwBody.onArrowKey(true, e, shift, ctrl, arrow, page, dir);
            }
        } finally {
            this._keyDownPending = false;
        }
    }
    
    /**
     * handles relevant "keyup" events
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     * @param {Boolean} tab "[Tab] key" flag
     * @param {Boolean} arrow "arrow key" flag
     * @param {Boolean} page "page up/down" flag
     * @param {Boolean} dir direction flag
     */
    _handleKeyUp(e, shift, ctrl, tab, arrow, page, dir) {
        try {
            // handle "keyup"
            if ( tab ) {
                this.xtwBody.onTabKey(false, e, shift, ctrl);
            } else {
                this.xtwBody.onArrowKey(false, e, shift, ctrl, arrow, page, dir);
            }
        } finally {
            this._keyUpPending = false;
        }
    }
}