import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import XCellItem from '../../parts/XCellItem';

export default class XRowItemEditingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get dropdownOpen() {
		if ( !Validator.isObject( this.tblBody ) ) {
			return false;
		}
		return !!this.tblBody.dropdownOpen;
	}

	get keyEventsFrozen() {
		if ( !Validator.isObject( this.tblBody ) ) {
			return false;
		}
		return !!this.tblBody.keyEventsFrozen;
	}

	get edited() {
		return this.modelItemIsValid && this.item.edited;
	}

	set edited( newValue ) {
		if ( !this.modelItemIsValid ) {
			return;
		}
		this.item.edited = !!newValue;
	}

	get firstVisibleColumn() {
		const xtwBody = this.tblBody;
		return Validator.isObject( xtwBody ) && "firstVisibleColumn" in xtwBody ? xtwBody.firstVisibleColumn : void 0;
	}

	get lastVisibleColumn() {
		const xtwBody = this.tblBody;
		return Validator.isObject( xtwBody ) && "lastVisibleColumn" in xtwBody ? xtwBody.lastVisibleColumn : void 0;
	}

	get firstVisibleColumnId() {
		const xtwBody = this.tblBody;
		return Validator.isObject( xtwBody ) && "firstVisibleColumnId" in xtwBody ? xtwBody.firstVisibleColumnId : void 0;
	}

	get lastVisibleColumnId() {
		const xtwBody = this.tblBody;
		return Validator.isObject( xtwBody ) && "lastVisibleColumnId" in xtwBody ? xtwBody.lastVisibleColumnId : void 0;
	}

	get firstEditableCell() {
		return this._getFirstCellBasedOnSelectorFunction( ( cell ) => {
			return Validator.isObject( cell ) && !cell.isBlob && !cell.shouldBeSkipped;
		} );
	}

	get firstEditableInputCell() {
		return this._getFirstCellBasedOnSelectorFunction( ( cell ) => {
			return Validator.isObject( cell ) &&
				// cell types 0, 1 and 2 are Text, Numeric and DateTime respectively
				cell.canBeEntered && [ 0, 1, 2 ].indexOf( cell.type ) >= 0;
		} );
	}

	get firstEditableVisibleInputCell() {
		return this._getFirstCellBasedOnSelectorFunction( cell => {
			const column = cell.column;
			return Validator.isObject( cell ) &&
				// cell types 0, 1 and 2 are Text, Numeric and DateTime respectively
				cell.canBeEntered && [ 0, 1, 2 ].indexOf( cell.type ) >= 0 && Validator.isObject( column ) && !!column.visible;
		} );
	}

	get lastEditableVisibleInputCell() {
		return this._getLastCellBasedOnSelectorFunction( ( cell ) => {
			const column = cell.column;
			return Validator.isObject( cell ) &&
				// cell types 0, 1 and 2 are Text, Numeric and DateTime respectively
				cell.canBeEntered && [ 0, 1, 2 ].indexOf( cell.type ) >= 0 &&
				Validator.isObject( column ) && !!column.visible;
		} );
	}

	get isFirstRenderedRow() {
		if ( !this.isRendered ) {
			return false;
		}
		const parentElement = this.element.parentElement;
		return parentElement instanceof HTMLElement && parentElement.firstElementChild === this.element;
	}

	_getMarginCellBasedOnSelectorFunction( selectorFunction, getFromStart = true ) {
		if ( !Validator.isFunction( selectorFunction ) ||
			!Validator.isObject( this.cells ) ||
			!Validator.isFunction( this.cells.getItem ) ) {
			return void 0;
		}
		const orderedColumnIds = this.orderedColumnIds;
		if ( !Validator.isArray( orderedColumnIds, true ) ) {
			return void 0;
		}
		let marginalCellID = !!getFromStart ? this.firstVisibleColumnId : this.lastVisibleColumnId;
		if ( !Validator.isPositiveInteger( marginalCellID ) ) {
			marginalCellID = orderedColumnIds[ 0 ];
		}
		let inspectedSelectorCell = this.cells.getItem( marginalCellID );
		const firstSelectorFunctionResult = !Validator.isObject( inspectedSelectorCell ) ? false : selectorFunction( inspectedSelectorCell );
		if ( !Validator.isBoolean( firstSelectorFunctionResult ) ) {
			return void 0;
		}
		if ( firstSelectorFunctionResult ) {
			return inspectedSelectorCell;
		}
		const marginalCellIndexLocation = Validator.getIndexInArray( orderedColumnIds, marginalCellID );
		if ( !Validator.isPositiveInteger( marginalCellIndexLocation ) ) {
			return void 0;
		}
		const directionProvider = !!getFromStart ? 1 : -1;
		let currentIdx = marginalCellIndexLocation + directionProvider;
		while ( currentIdx < orderedColumnIds.length && currentIdx >= 0 ) {
			inspectedSelectorCell = this.cells.getItem( orderedColumnIds[ currentIdx ] );
			currentIdx += directionProvider;
			if ( !Validator.isObject( inspectedSelectorCell ) || !selectorFunction( inspectedSelectorCell ) ) {
				continue;
			}
			break;
		}
		return Validator.isObject( inspectedSelectorCell ) && selectorFunction( inspectedSelectorCell ) ? inspectedSelectorCell : void 0;
	}

	_getLastCellBasedOnSelectorFunction( selectorFunction ) {
		return this._getMarginCellBasedOnSelectorFunction( selectorFunction, false );
	}

	_getFirstCellBasedOnSelectorFunction( selectorFunction ) {
		return this._getMarginCellBasedOnSelectorFunction( selectorFunction, true );
	}

	displayAsEdited() {
		this.addPen();
		const element = this.element;
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		element.classList.add( "rtp-edited" );
		return true;
	}

	displayAsUnedited() {
		this._removeEditingPen();
		const element = this.element;
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		element.classList.remove( "rtp-edited" );
		return true;
	}

	addPen() {
		return this.isFocused ? this._addFocusedEditingPen() : this._addEditingPen();
	}

	_addFocusedEditingPen() {
		this._removeSelectionArrow();
		const selectionCell = this.selectionCell;
		if ( !(selectionCell instanceof XCellItem) ) {
			return false;
		}
		return selectionCell.addFocusedEditingPen();
	}

	_addEditingPen() {
		this._removeSelectionArrow();
		const selectionCell = this.selectionCell;
		if ( !Validator.is( selectionCell, "XCellItem" ) ) {
			return false;
		}
		return selectionCell.addEditingPen();
	}

	_removeEditingPen() {
		const selectionCell = this.selectionCell;
		if ( !(selectionCell instanceof XCellItem) ) {
			return false;
		}
		return selectionCell.removeEditingPen();
	}

	_updateEditedFocusedUI( setToFocused ) {
		if ( !this.edited || !this.isRendered ) {
			return false;
		}
		if ( !!setToFocused ) {
			this.element.classList.add( "rtp-focused" );
			return this._addFocusedEditingPen();
		}
		this.element.classList.remove( "rtp-focused" );
		return this._addEditingPen();
	}

}
