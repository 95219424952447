import FocusHolder from "../../../../gui/FocusHolder";
import EditRequest from "./EditRequest";

export default class AbstractEditable extends FocusHolder {

    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'widgets.xtw.editing.AbstractEditable');
    }

    /**
     * @override
     */
    doDestroy() {
        super.doDestroy();
    }

	/**
	 * returns an internal type info string
	 * @returns {String} an internal type info string
	 */
    getType() {
		return 'abstract';
	}

    /**
     * registers this instance with table body
     * @returns {Boolean} true if editable was registered; false otherwise
     */
    register() {
        // abstract
        return false;
    }

    /**
     * un-registers this instance with table body
     * @returns {Boolean} true if editable was un-registered; false otherwise
     */
    unregister() {
        // abstract
        return false;
    }

    /**
     * @inheritdoc
     * @override
     */
    flush() {
        if ( this.alive ) {
            this.informAboutContentChange();
        }
    }

    /**
     * renders this editable
     */
    render() {
        // abstract
    }

    /**
     * destroys this editable and restores the table cell
	 * @returns {Boolean} true if successful; false otherwise
     */
    destroySelfAndRestoreCell() {
        // abstract
        return true;
    }

    /**
     * indicates whether the input element needs to process an arrow key
     * @param {Boolean} horz true: horizontal arrow key (left or right); false: vertical arrow key (up or down)
     * @param {Boolean} upleft true: direction is up or left; false: direction is down or right
     * @param {KeyboardEvent} ke the keyboard event
     * @returns {Boolean} true if the input element needs the arrow key; false if the key can trigger cell navigation
     */
    needsArrowKey(horz, upleft, ke) {
        return false;
    }

    /**
     * sets the editing permission
     * @param {Boolean} editingAllowed true if editing is allowed; false otherwise
     * @returns {Boolean} true if successful; false otherwise
     */
    setEditingPermission(editingAllowed) {
        return true;
    }

	/**
	 * sets the "override" original value
	 * @param {String} ovr new "override" original value
	 */
	setOvrOrgValue(ovr) {
        // abstract
	}

    /**
     * informs the editable element about the dropdown state
     * @param {Boolean} open flag whether the dropdown is shown (true) or closed (false)
     * @param {String} wdgId widget ID of the main dropdown widget
     */
    setDropdownOpenState( open, wdgId ) {
        // abstract
    }

    /**
     * synchronizes the input value and the optional dropdown lit with new content
     * @param {*} args arguments object
     */
    syncInputContentWithDropdown(args) {
        // abstract
    }

	/**
	 * called if the content was changed
	 * @returns {Boolean} true if changed content was sent to the web server; false otherwise
	 */
	informAboutContentChange() {
		// abstract
		return false;
	}

    /**
     * informs this instance about a "cancel" request
     * @param {*} parameters 
     * @param {Boolean} blockScreenRequest 
	 * @returns {Boolean} true if successful
     */
    informAboutCancel( parameters = {}, blockScreenRequest = false ) {
        // abstract
        return false;
    }

    /**
     * informs this instance about a "save" request
     * @param {*} parameters 
     * @param {Boolean} blockScreenRequest 
	 * @returns {Boolean} true if successful
     */
    informAboutSave( parameters = {}, blockScreenRequest = false ) {
        // abstract
        return false;
    }

    /**
     * informs this instance about a "full save" request
     * @param {*} parameters 
     * @param {Boolean} blockScreenRequest 
	 * @returns {Boolean} true if successful
     */
    informAboutFullSave( parameters = {}, blockScreenRequest = false ) {
        // abstract
        return false;
    }

    /**
     * creates an edit request that represents this editable
     * @returns {EditRequest} an edit request that represents this editable
     */
    createEditRequest() {
        return null;
    }

	/**
	 * @returns {Boolean} true if the current edit mode is an "insert" mode; false otherwise
	 */
	get insertMode() {
        // abstract
        return false;
	}

    /**
     * @returns {Boolean} true if this editable is in "dropped down" state; false otherwise
     */
    get droppedDown() {
        // abstract
        return false;
    }
}