import LoggingBase from '../../../base/loggingbase';
import ObjReg from '../../../utils/ObjReg';
import MRowItem from './MRowItem';
import MGroup from './MGroup';
import SelectionMgr, { ROWID_DUMMY, ROWID_NONE } from './SelectionMgr';
import Validator from '../../../utils/Validator';
import { EMPTY_ARR, EMPTY_OBJ } from '../../../base/base';
import MDataRow from './MDataRow';
import MCell from './MCell';

/** the built-in identifier for the default group - keep in sync. with "de.pisa.webcli.cstwdg.xtdtbl.impl.XtwModel.DEFAULT_DSC"! */
const DEFAULT_DSC = 'XTDGRP_DEFAULT_79B944AA3F7844D29F717D18D00FA7AD';

/** minimal fetch counter - fetch at least this number of rows, if more rows are available */
const MIN_FETCH_CNT = 128;

/**
 * eXtended Table Widget Data Model class
 */
export default class XtwModel extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {XtwBody} xtb the associated table widget's body
	 * @param {Number} dgh default group height
	 * @param {Number} drh default row height
	 */
	constructor( xtb, dgh, drh ) {
		super('widgets.xtw.XtwModel');
		this.tblBody = xtb;
		this.selectionMgr = new SelectionMgr(this);
		this.flatModel = [];
		this.defGrh = dgh;
		this.defRwh = drh;
		this.groups = new ObjReg();
		this.items = new Map();
		this.deadItems = new Set();
		this.defGrp = new MGroup(this, { idg: 0, dsc: DEFAULT_DSC, height: 0 }, 0, this.defRwh);
		this.log('New XtwModel instance created');
	}

	/**
	 * @override
	 */
	doDestroy() {
		this.clear();
		this.selectionMgr.destroy();
		this.groups.destroy();
		this.defGrp.destroy();
		delete this.defGrh;
		delete this.defRwh;
		delete this.groups;
		delete this.defGrp;
		delete this.selectionMgr;
		delete this.flatModel;
		delete this.tblBody;
		super.doDestroy();
	}

	/**
	 * drops all current data
	 */
	clear() {
		this.selectionMgr.clearAll();
		this.items.clear();
		this.groups.dstChl();
		this.defGrp.clear();
		this.deadItems.clear();
		if ( this.flatModel.length > 0 ) {
			this.flatModel = [];
		}
	}

	/**
	 * returns the total number of items in the flat model
	 * @returns {Number} the total number of items in the flat model
	 */
	getItemCount() {
		return this.itemCount;
	}

	/**
	 * @returns {Number} the total number of items in the flat model
	 */
	 get itemCount() {
		return this.flatModel.length;
	}

	/**
	 * retrieves a model item by index
	 * @param {Number} tix current top index
	 * @param {Number} idx item index
	 * @returns {MRowItem} the model item or null if the index is out of bounds
	 */
	getModelItem( tix, idx ) {
		const fm = this.flatModel;
		if ( ( idx >= 0 ) && ( idx < fm.length ) ) {
			return fm[ idx ];
		} else {
			return null;
		}
	}

	/**
	 * retrieves the flat index of a model item
	 * @param {Number} idr row ID
	 * @returns {Number} the flat index or -1 if not found
	 */
	getFlatIndex(idr) {
		return this.flatModel.findIndex((ri) => ri.idr === idr);
	}

	/**
	 * retrieves a group
	 * @param {String} dsc group descriptor (group name)
	 * @returns {MGroup} the matching group or null if there's no such group
	 */
	getGroup(dsc) {
		return this.groups.getObj( dsc );
	}

    /**
     * checks whether the specified row is selected
     * @param {Number} idr row ID
     * @returns {Boolean} true if the specified row is selected; false otherwise
     */
	isRowSelected(idr) {
		return this.selectionMgr.isSelected(idr);
	}

	/**
     * checks whether the specified row item is selected
	 * @param {MRowItem} item the row item
     * @returns {Boolean} true if the specified row item is selected; false otherwise
	 */
	isItemSelected(item) {
		return (item instanceof MRowItem) ? this.isRowSelected(item.idr) : false;
	}

	/**
	 * checks whether at least one of the given rows is selected
	 * @param {Number[]} rows an array of row IDs
	 * @returns {Boolean} true if at least one of the given rows is selected; false otherwise
	 */
	isOneSelected(rows) {
		const sm = this.selectionMgr;
		for ( let r of rows ) {
			if ( sm.isSelected(r) ) {
				return true;
			}
		}
		return false;
	}

    /**
     * @returns {Number} the ID of the currently focused row
     */
	get focusedRow() {
        return this.selectionMgr.focusedRow;
    }

    /**
     * @returns {Number} the ID of the currently focused column
     */
	get focusedColumn() {
		return this.selectionMgr.focusedColumn;
	}

    /**
     * checks whether the specified row is focused
     * @param {Number} idr  row ID
     * @returns {Boolean} true if the specified row is focused; false otherwise
     */
    isRowFocused(idr) {
        return this.selectionMgr.isFocused(idr);
    }

    /**
     * checks whether the specified row item is focused
     * @param {MRowItem} item the row item
     * @returns {Boolean} true if the specified row item is focused; false otherwise
     */
	isItemFocused(item) {
		return (item instanceof MRowItem) ? this.isRowFocused(item.idr) : false;
	}

	/**
	 * calculates the page scroll distance
	 * @param {Boolean} up direction flag
	 * @param {Number} tix current top index
	 * @param {Number} cnt number of visible row items
	 * @returns {Number} the page scroll distance in pixels
	 */
	getPageScrollDist( up, tix, cnt ) {
		const fm = this.flatModel;
		const lim = fm.length;
		let dist = 0;
		if ( ( lim > 0 ) && ( tix >= 0 ) && ( cnt > 0 ) ) {
			let idx = Math.min( tix, lim - 1 );
			if ( up && ( tix < cnt ) ) {
				idx = Math.min( tix + 1, lim - 1 );
			}
			let i = 0;
			if ( up ) {
				while ( ( i < cnt ) && ( idx >= 0 ) ) {
					dist += fm[ idx ].getHeight();
					++i;
					--idx;
				}
			} else {
				while ( ( i < cnt ) && ( idx < lim ) ) {
					dist += fm[ idx ].getHeight();
					++i;
					++idx;
				}
			}
		}
		return dist;
	}

	/**
	 * retrieves the index of the row item at the specified vertical scroll position
	 * @param {Number} vsp the vertical scroll position
	 * @param {Number} cti current top index
	 * @param {Boolean} up scrolling direction
	 * @returns {Number} the index of the top item or -1 if there's no such item
	 */
	getNextTopIdx( vsp, cti, up ) {
		// some pre-conditions and checks for both ends
		const fi = this.flatModel;
		if ( !fi.length ) {
			return -1;
		}
		if ( vsp <= 0 ) {
			return 0;
		}
		const last = fi.length - 1;
		if ( last === 0 ) {
			return 0;
		}
		let tix = vsp;
		if ( ( tix !== -1 ) && ( tix !== last ) && ( tix === cti ) ) {
			if ( up ) {
				tix = Math.max( cti - 1, 0 );
			} else {
				tix = Math.min( cti + 1, last );
			}
		}
		return tix;
	}

    /**
     * adds a new selection listener
     * @param {SelectionLsr | Function} listener the listener to be added
     */
	addSelectionListener(listener) {
		this.selectionMgr.addSelectionListener(listener);
    }

    /**
     * adds a new row focus listener
     * @param {RowFocusLsr | Function} listener the listener to be added
     */
    addRowFocusListener(listener) {
		this.selectionMgr.addRowFocusListener(listener);
    }

    /**
     * adds a new column focus listener
     * @param {ColumnFocusLsr | Function} listener the listener to be added
     */
	addColumnFocusListener(listener) {
		this.selectionMgr.addColumnFocusListener(listener);
	}

	/**
	 * called by the web server if the data model has been committed
	 * @param {Object} json new data model
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad vertical padding in pixels
	 * @param {Function} cbf callback function
	 * @returns {Number} the total height in pixels of all items
	 */
	modelCommitted( json, rtp, rh, vpad, cbf ) {
		this.log('modelCommitted');
		// drop current data
		this.clear();
		// fill model
		const self = this;
		const model = json.model || EMPTY_ARR;
		const selected = [];
		model.forEach( ( md ) => {
			const mi = self._createModelItem( md, false, 0 );
			if ( (mi instanceof MRowItem) && !!md.select ) {
				selected.push(mi.idr);
			}
		} );
		// update flat model and process model UI changes
		const height = this.modelGroupExpanded( rtp, rh, vpad );
		// call callback function if provided		
		if ( typeof cbf === 'function' ) {
			cbf();
		}
		// deal with focus and selection		
		const focus = json.focusRow || 0;
		if ( (focus > 0) && this.items.has(focus) ) {
			this.selectionMgr.focusRow(focus);
		}
		if ( selected.length > 0 ) {
			this.selectionMgr.selectRows(selected);
		}
		return height;
	}

	/**
	 * called if a group was expanded or collapsed
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad vertical padding in pixels
	 * @returns {Number} the total height in pixels of all items
	 */
	modelGroupExpanded( rtp, rh, vpad ) {
		const vp = rtp ? vpad : 0;
		return this._updateFlatModel( rtp, rh, vp );
	}

	/**
	 * processes model data sent by the web server
	 * @param {Array} items array of data items
	 */
	modelData( items ) {
		this.log('modelData');
		const self = this;
		( items || EMPTY_ARR ).forEach( ( item ) => {
			self._updateModelData( item );
		} );
	}

	/**
	 * sets the rows to be updated
	 * @param {Array<Number>} rows an array providing the IDs of the rows to be updated
	 */
	modelUpdate( rows ) {
		const items = this.items;
		if ( items.size === rows.length ) {
			// *all* model items!
			items.forEach( ( mi ) => {
				mi.invalidate();
			} );
		} else {
			rows.forEach( ( idr ) => {
				const mi = items.get( idr ) || null;
				if ( mi ) {
					mi.invalidate();
				}
			} );
		}
	}

	/**
	 * invalidates all model items
	 */
	modelInvalidate() {
		this.items.forEach( (mi) => mi.invalidate() );
	}

	/**
	 * clears the "edited" state of all model items
	 */
	clearEditedState() {
		this.items.forEach( (mi) => mi.clearEdited() );
	}

	/**
	 * deletes rows from the model
	 * @param {Number[]} rows an array providing the IDs of the rows to be deleted
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad vertical padding in pixels
	 * @returns {Number} the height in pixels of the new flat model
	 */
	deleteRows(rows, rtp, rh, vpad) {
		const dr = [];
		const self = this;
		rows.forEach((idr) => {
			const mi = self.items.get(idr);
			if ( (mi instanceof MRowItem) && mi.isDataRow() ) {
				dr.push(mi);
			}
		});
		dr.forEach( (r) => {
			const idr = r.idr;
			r.group.deleteRow(r);
			self.items.delete(idr);
			self.deadItems.add(idr);
		});
		return this._updateFlatModel(rtp, rh, vpad);
	}

	/**
	 * inserts a new row
	 * @param {*} args JSON object providing row properties
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad total vertical padding in pixels
	 * @returns {Number} the height in pixels of the new flat model
	 */
	insertRow(args, rtp, rh, vpad) {
		const jrow = args.row;
		const target = args.ref;
		const rmv_dummy = !!args.dummy;
		const is_add = !!args.mode;
		this._createModelItem(jrow, !is_add, target);
		if ( rmv_dummy ) {
			const dr = this.items.get(ROWID_DUMMY);
			if ( (dr instanceof MRowItem) && dr.isDataRow() ) {
				dr.group.deleteRow(dr);
				this.items.delete(ROWID_DUMMY);
				this.deadItems.add(ROWID_DUMMY);
			}
		}
		return this._updateFlatModel(rtp, rh, vpad);
	}

	/**
	 * called if the view mode was changed (classic view vs. Row Template)
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad total vertical padding in pixels
	 * @returns {Number} the total height in pixels of all items
	 */
	viewModeChanged( rtp, rh, vpad ) {
		const fm = this.flatModel;
		if ( !fm.length ) {
			return 0;
		}
		const ovh = rtp ? rh : null;
		const vp = rtp ? vpad : 0;
		let top = 0;
		fm.forEach( ( mi ) => {
			mi.setOvrHeight( ovh, vp );
			mi.setTop( top );
			top += mi.getHeight() + vp;
		} );
		return top;
	}

	/**
	 * updates a model item
	 * @param {Object} item data item as sent by the web server
	 */
	_updateModelData( item ) {
		const di = item.ri || EMPTY_OBJ;
		const idr = item.idr || 0;
		if ( idr <= 0 || idr !== di.idr ) {
			this.warn(`XtwModel#_updateModelData - invalid JSON data! Different ID values "${ idr }" !== "${ di.idr }".`);
			return;
		}
		if ( this.deadItems.has(idr) ) {
			if ( this.isTraceEnabled() ) {
				this.trace(`XtwModel#_updateModelData -ignoring dead item for row ID "${ idr }"!`)
			}
			return;
		}
		const mi = this.items.get( idr );
		if ( !(mi instanceof MRowItem) ) {
			this.warn(`XtwModel#_updateModelData - could not find any model item with the row ID of "${ idr }"!`);
			return;
		}
		if ( mi.alive ) {
			mi.setData( di );
		} else if ( this.isTraceEnabled() ) {
			this.trace(`XtwModel#_updateModelData - found a dead item for row ID "${ idr }"!`)
		}
	}

	/**
	 * returns an information which data items must be fetched from web server
	 * @param {Number} tix top / starting index
	 * @param {Number} cnt number of items to be fetched
	 */
	fetchData( tix, cnt ) {
		const rqu_lst = [];
		if ( (tix < 0) || (cnt <= 0) ) {
			return null;
		}
		const fm = this.flatModel;
		const lim = fm.length;
		let idx = tix;
		for ( let i = 0; (i < cnt) && (idx < lim); ++i, ++idx ) {
			const mi = fm[ idx ];
			if ( !mi.isPresent() ) {
				rqu_lst.push( mi.getRowID() );
			}
		}
		if ( (rqu_lst.length > 0) && ((rqu_lst.length < cnt) || (tix === 0)) ) {
			// fill it up!
			const max_cnt = Math.max(MIN_FETCH_CNT, 2 * cnt);
			idx = tix + rqu_lst.length;
			for ( let hit = rqu_lst.length; (hit < max_cnt) && (idx < lim); ++idx ) {
				const mi = fm[ idx ];
				if ( !mi.isPresent() ) {
					rqu_lst.push( mi.getRowID() );
					++hit;
				}
			}
		}
		return (rqu_lst.length > 0) ? rqu_lst : null;
	}

	/**
	 * creates a model item from data sent by the web server
	 * @param {Object} md the model item data to be processed
	 * @param {Boolean} insert flag whether to insert the model item at a specific location append the model item
	 * @param {Number} target ID of insert target
	 * @returns {MRowItem} the model item
	 */
	_createModelItem( md, insert, target ) {
		const type = !!md.type;
		const mi = type ? this._creGrp( md ) : this._getGrp( md ).addRow( md, insert, target );
		if ( mi instanceof MRowItem ) {
			const idr = mi.idr;
			this.items.set(idr, mi);
			this.deadItems.delete(idr);
		}
		return mi;
	}

	/**
	 * updates the current flat model
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad vertical padding in pixels
	 * @returns {Number} the height in pixels of all row items
	 */
	_updateFlatModel( rtp, rh, vpad ) {
		let top = 0;
		this.flatModel = [];
		const fm = this.flatModel;
		const ovh = rtp ? rh : null;
		const vp = rtp ? vpad : 0;
		if ( this.defGrp ) {
			top += this.defGrp.addToFlat( fm, top, ovh, vp );
		}
		if ( this.groups && !this.groups.isEmpty() ) {
			this.groups.forEach( ( g ) => {
				top += g.addToFlat( fm, top, ovh, vp );
			} );
		}
		return top;
	}

	/**
	 * retrieves the group of an item
	 * @param {Object} mi model item
	 * @returns {MGroup} the target group
	 */
	_getGrp( mi ) {
		const idg = mi.idg || 0;
		const dsc = mi.dsc || '';
		if ( idg === 0 || DEFAULT_DSC === dsc ) {
			return this.defGrp;
		}
		const grp = this.groups.getObj( dsc );
		return grp ? grp : this.defGrp;
	}

	/**
	 * creates a new group
	 * @param {Object} md the model item data to be processed
	 * @returns {MGroup} new created group
	 */
	_creGrp( md ) {
		const idg = md.idg || 0;
		const dsc = md.dsc || '';
		if ( idg === 0 || DEFAULT_DSC === dsc || this.groups.hasObj( dsc ) ) {
			throw new Error( 'Invalid group descriptor "' + dsc + '" (id=' + idg + ')!' );
		}
		const grp = new MGroup(this, md, this.defGrh, this.defRwh );
		this.groups.addObj( dsc, grp );
		return grp;
	}

	/**
	 * retrieves the model item with the given row ID
	 * @param {Number} idr row ID
	 * @returns {MRowItem} the model item or null if no valid model was found
	 */
	getDataRowModelItem( idr ) {
		const modelItem = this.items.get( idr );
		if ( !(modelItem instanceof MRowItem) ) {
			this.warn(`Row ID "${idr}" is either invalid or refers to an invalid item!`);
			return null;
		}
		return modelItem;
	}

	/**
	 * updates the content of a data cell
	 * @param {Number | String} idc column ID
	 * @param {Number} idr row ID
	 * @param {*} json JSON data providing new cell content
	 * @returns {Boolean} true if a data cell was successfully updated; false otherwise
	 */
	updateCell(idc, idr, json) {
		const row = this.getDataRowModelItem(idr);
		if ( row instanceof MDataRow ) {
			const cell = row.getCell(idc);
			if ( cell instanceof MCell ) {
				cell.setCtt(json);
				return true;
			}
		}
		return false;
	}

	/**
	 * updates the text content of a data cell
	 * @param {Number | String} idc column ID
	 * @param {Number} idr row ID
	 * @param {String} text new cell content
	 * @returns {Boolean} true if a data cell was successfully updated; false otherwise
	 */
	updateCellText(idc, idr, text) {
		const row = this.getDataRowModelItem(idr);
		if ( row instanceof MDataRow ) {
			const cell = row.getCell(idc);
			if ( cell instanceof MCell ) {
				cell.setText(text);
				return true;
			}
		}
		return false;
	}

	/**
	 * sets a new row height
	 * @param {Number} rh new (default) row height
	 */
	setRowHeight(rh) {
		this.defRwh = rh;
		this.items.forEach( (mi) => {
			if ( mi.isGroupHead() ) {
				mi.defRwh = rh;
			} else {
				mi.height = rh;
			}
		} );
	}

    /**
     * causes a single row to be selected
     * @param {Number} idr ID of currently selected row
	 * @param {Boolean} notify flag whether to notify the web server
     */
	selectSingleRow(idr, notify) {
		if ( this.items.has(idr) ) {
			this.selectionMgr.selectSingle(idr, notify);
		}
	}

    /**
     * causes a single row to be unselected
     * @param {Number} idr ID of currently unselected row
	 * @param {Boolean} notify flag whether to notify the web server
     */
	unselectSingle(idr, notify) {
		if ( this.items.has(idr) ) {
			this.selectionMgr.unselectSingle(idr, notify);
		}
	}

    /**
     * selects a row
     * @param {Number} idr ID of row that's selected
     * @param {Boolean} notify "notify web server" flag
     */
	selectDataRow( idr, notify ) {
		if ( this.items.has(idr) ) {
			this.selectionMgr.selectRow(idr, notify);
		}
	}

    /**
     * selects one or more rows at once
     * @param {Array<Number>} rows IDs of rows to be selected
     * @param {Boolean} notify "notify web server" flag
     */
	selectRows(rows, notify) {
		this.selectionMgr.selectRows(rows);
	}

    /**
     * causes a single row to be unselected
     * @param {Number} idr ID of currently unselected row
	 * @param {Boolean} notify flag whether to notify the web server
     */
	deselectDataRow( idr, notify ) {
		if ( this.items.has(idr) ) {
			this.selectionMgr.unselectSingle(idr, notify);
		}
	}

    /**
     * unselects all currently selected rows
	 * @param {Boolean} notify flag whether to notify the web server
     */
	unselectAll(notify) {
		this.selectionMgr.unselectAll(notify);
	}

	/**
	 * toggles the "select all" status - selects all data rows if now row is currently selected;
	 * unselects all rows if there's at least one selected data row
	 */
	toggleSelectAll() {
		const sm = this.selectionMgr;
		if ( sm.hasSelection ) {
			// unselect all rows
			sm.unselectAll(true);
		} else {
			// select all data rows
			sm.clearSelection(false);
			const fm = this.flatModel;
			fm.forEach((row) => {
				if ( row.isDataRow() && !row.isDummyRow() ) {
					sm.selectSilent(row.getRowID());
				}
			});
			if ( sm.focusedRow === ROWID_NONE ) {
				sm.focusFirstSelected();
			}
			sm.callSelectLsr();
		}
	}

    /**
     * sets the row focus on the specified row
     * @param {Number} idr ID of row to become focused
     */
	focusDataRow( idr ) {
		if ( this.items.has(idr) ) {
			this.selectionMgr.focusRow(idr);
		}
	}

    /**
     * unfocuses the specified row if it is currently the focused row
     * @param {Number} idr ID of row that's unfocused
     */
	unfocusDataRow( idr ) {
		if ( this.items.has(idr) ) {
			this.selectionMgr.unfocusRow(idr);
		}
	}

	/**
	 * tries to focus the next suitable item
	 * @param {Number} fix index of currently focused item
	 * @param {Boolean} select flag whether to select the found item
	 */
	focusNextItem(fix, select) {
		const limit = this.getItemCount();
		let nfx = Math.max(0, Math.min(fix, limit-1));
		let fi = this.getModelItem(0, nfx);
		if ( fi instanceof MRowItem ) {
			while ( (fi instanceof MRowItem) && (nfx > 0) && (fi.isDummyRow() || fi.isGroupHead()) ) {
				if ( fi.isDummyRow() && (nfx > 0) ) {
					--nfx;
					fi = this.getModelItem(0, nfx);
				} else if ( fi.isGroupHead() ) {
					// stop here and do nothing so far
					fi = null;
				} else {
					// giving up
					fi = null;
				}
			}
			if ( fi instanceof MRowItem ) {
				this.focusDataRow(fi.idr);
				if ( select ) {
					this.selectSingleRow(fi.idr);
				}
			}
		}
	}

	/**
	 * sets the focused column
	 * @param {Number} idc ID of column that's currently focused; -1 if none
	 */
	setFocusColumn(idc) {
		if ( Validator.isInteger(idc) ) {
			this.selectionMgr.setFocusColumn(idc);
		}
	}

    /**
     * called on custom "cell clicked" events
     * @param {Event} e the custom DOM event
     */
	onCellClicked(e) {
        const detail = e.detail || {};
        const idr = detail.rowId;
        if ( Validator.isInteger(idr) ) {
			const row = this.getDataRowModelItem(idr);
			if ( row !== null ) {
				const xtb = this.tblBody;
				const none = xtb.selectionNone;
				if ( !none ) {
					const forced = xtb.selectionSingleForced;
					const single = forced || xtb.selectionSingle;
					const shift = !single && !!detail.shiftPressed;
					const ctrl = !single && !!detail.controlPressed;
					if ( shift ) {
						// expand selection
						const fcr = this.focusedRow !== ROWID_NONE ? this.getDataRowModelItem(this.focusedRow) : null;
						if ( fcr !== null ) {
							const beg = Math.min(fcr.flatIndex, row.flatIndex);
							const end = Math.max(fcr.flatIndex, row.flatIndex);
							if ( (beg !== end) || !this.isRowSelected(this.focusedRow) ) {
								const rows = [];
								for ( let idx=beg ; idx <= end ; ++idx ) {
									const ri = this.getModelItem(0, idx);
									if ( ri && !ri.isGroupHead() ) {
										rows.push(ri.getRowID());
									}
								}
								if ( rows.length > 0 ) {
									this.selectRows(rows, true);
								}
							}
						} else {
							// no currently focused row - do a single selection
							this.selectSingleRow(idr, true);
						}
					} else if ( ctrl ) {
						// toggle row selection
						if ( this.isRowSelected(idr) ) {
							this.unselectSingle(idr, true);
						} else {
							this.selectDataRow(idr, true);
						}
					} else {
						// select a single data row
						this.selectSingleRow(idr, true);
					}
				}
				// always set the focus to the clicked row!
				this.focusDataRow(idr);
				// and always set the focus to the clicked column (if applicable)
				const idc = detail.colId;
				if ( Validator.isInteger(idc) && (idc > 0) ) {
					this.setFocusColumn(idc);
				}
			}
        }
    }

}
