import MRowItem from './MRowItem';
import ObjReg from '../../../utils/ObjReg';
import MGroup from './MGroup';
import MCell from './MCell';
import XtwModel from './XtwModel';
import { ROWID_NONE } from './SelectionMgr';
import { EMPTY_ARR } from '../../../base/base';

/**
 * data row class
 */
export default class MDataRow extends MRowItem {

	/**
	 * constructs a new instance
	 * @param {XtwModel} model the model to which this item belongs
	 * @param {Object} JSON data sent by the web server
	 * @param {Number} drh default row height
	 * @param {MGroup} group the group to which this row belongs
	 */
	constructor( model, json, drh, group ) {
		super( model, json );
		this._height = json.height || drh;
		const dummy = !!json.dummy;
		const readOnly = !!json.readOnly;
		Object.defineProperty( this, "_dummy", { value: dummy, writable: false, configurable: false } );
		Object.defineProperty( this, "_readOnly", { value: readOnly, writable: false, configurable: false } );
		this.cells = new ObjReg();
		this._group = group;
		this._present = dummy; // the dummy row is always present
		this._edited = false;
		if ( typeof json.edited !== 'undefined' ) {
			this._edited = !!json.edited;
		}
	}

	/**
	 * @override
	 */
	doDestroy() {
		if ( this.cells ) {
			this.cells.dstChl();
		}
		delete this.cells;
		delete this._group;
		super.doDestroy();
	}

	get rowId() {
		if ( this.readOnlyDummy ) {
			return ROWID_NONE;
		}
		return this.xid;
	}

	get xtwModel() {
		return this.model;
	}

	get xtwBody() {
		return this.model.tblBody;
	}


	/**
	 * @returns {MGroup} the group of this data row
	 */
	get group() {
		return this._group;
	}

	/**
	 * @returns {Boolean} true if this row is in "edit" mode; false otherwise
	 */
	get edited() {
		return this.alive && this._edited && !this.dummy;
	}

	/**
	 * sets the new "edit" mode
	 * @param {Boolean} newValue new "edit" mode
	 */
	set edited( newValue ) {
		this._edited = !!newValue;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	isDataRow() {
		return true;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	isDummyRow() {
		return this.dummy;
	}

	/**
	 * @returns {Boolean} true if this is a dummy row; false otherwise
	 */
	get dummy() {
		return this._dummy;
	}

	get present() {
		return super.present || this.dummy;
	}

	/**
	 * @returns {Boolean} true if this row is marked as "read/only"; false otherwise
	 */
	get readOnly() {
		return this._readOnly;
	}

	/**
	 * @returns {Boolean} true if this is a "read/only" dummy row; false otherwise
	 */
	get readOnlyDummy() {
		return this.readOnly && this.dummy;
	}

	/**
	 * @returns {Boolean} true if this is a dummy row that allows to insert a new record
	 */
	get insertionDummy() {
		return this.dummy && !this.readOnly;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	setData( data ) {
		super.setData( data );
		if ( ( this.idr === data.idr ) && ( data.type === false ) ) {
			const cells = data.cells || EMPTY_ARR;
			const self = this;
			cells.forEach( ( cd ) => {
				self._setCell( cd );
			} );
			this._present = true;
			if ( typeof data.edited !== 'undefined' ) {
				this._edited == !!data.edited;
			}
		} else {
			console.error( 'Invalid data item!' );
			console.error( data );
			throw new Error( 'Invalid data item!' );
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	isPresent() {
		return this._present || !!this._dummy;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	clearEdited() {
		this._edited = false;
	}

	/**
	 * retrieves a data cell
	 * @param {Number} idc column ID
	 * @returns {MCell} the data cell or null
	 */
	getCell( idc ) {
		return this.cells.getObj( idc );
	}

	/**
	 * returns all data cells as iterable object
	 * @returns {IterableIterator<MCell>} an iterable object of all data cells
	 */
	getCells() {
		return this.cells;
	}

	/**
	 * creates or updates a data cell and sets its content
	 * @param {Object} cd cell data
	 */
	_setCell( cd ) {
		const idc = cd.idc || 0;
		if ( idc && ( idc !== -1 ) ) {
			const cell = this.cells.getObj( idc );
			if ( !cell ) {
				this.cells.addObj( idc, new MCell( cd ) );
			} else {
				cell.setCtt( cd.ctt );
			}
		}
	}
	
}

console.debug("widgets/xtw/model/MDataRow.js loaded");