import PSA from '../psa';
import FileDropHandler from '../dnd/FileDropHandler';
import Utils from '../utils/Utils';
import Validator from '../utils/Validator';
import Logging from '../utils/logging';

const ClientDocument = rwt.widgets.base.ClientDocument;

/**
 * ConMgr - HTTP connection manager class
 */
export default class ConMgr extends FileDropHandler {

	/**
	 * constructs a new instance
	 * @param {CliCbkWdg} cbw callback widget
	 */
	constructor(cbw) {
		super('ssn.ConMgr');
		this.iniDon = false;
		this.cbkWdg = null;
		this.rwtCon = null;
		this.bscMgr = null;
		this.ignRqu = false;
		this.rquCnt = 0;
		this.logCnt = 0;
		this.pingTmh = null;
		this.onSend = null;
		this.onRender = null;
		this._init(cbw);
	}

	/**
	 * called on logout to clean-up
	 */	
	destroy() {
		this.cbkWdg = null;
		this.bscMgr = null;
		if ( this.pingTmh ) {
			const tmh = this.pingTmh;
			this.pingTmh = null;
			window.clearInterval(tmh);
		}
		if ( this.onSend ) {
			rap.off('send', this.onSend);
		}
		if ( this.onRender ) {
			rap.off('render', this.onRender);
		}
		delete this.onSend;
		delete this.onRender;
		if ( this.rwtCon ) {
			let con = this.rwtCon;
			if ( typeof con._org_retry === 'function' ) {
				con._retry = con._org_retry;
				delete con._org_retry;
			}
			// we do *not* restore the original error handler but set a dummy instead...
			con._handleError = function(evt) {
				// not nothing here - we're logged out...
			};
			if ( typeof con._org_handleError === 'function' ) {
				delete con._org_handleError;
			}
			this.rwtCon = null;
			this.iniDon = false;
		}
		// restore RAP's mouse cursor handling
		let cli = ClientDocument.getInstance();
		if ( cli && (typeof cli._org_applyGlobalCursor === 'function') ) {
			cli._applyGlobalCursor = cli._org_applyGlobalCursor;
			delete cli._org_applyGlobalCursor;
		}
	}

	/**
	 * initializes client-side block screen management
	 * @param {Object} args arguments sent by the web server; may specify a block screen timeout (optional)
	 */
	iniBlkScr(args) {
		if ( this.iniDon ) {
			return;
		}
		this.onSend = Utils.bind(this, this._onSend);
		rap.on('send', this.onSend);
		this.onRender = Utils.bind(this, this._onRender);
		rap.on('render', this.onRender);
		const self = this;
		const con = this.rwtCon;
		if ( con ) {
			const hdl_err = con._handleError;
			if ( typeof hdl_err === 'function' ) {
				con._org_handleError = hdl_err;
				con._handleError = function(evt) {
					try {
						if ( self._isDbgLog() ) {
							const rcn = self._getRquCnt();
							self.trace("ERR #" + rcn);
						}
						con._org_handleError(evt);
					} finally {
						self._showBsc(false);
					}
				};
				// setup extended error handling (JS and JSON errors)
				this._iniXtrErrHdl();
				// disable any mouse cursor handling by RAP
				const cli = ClientDocument.getInstance();
				if ( cli && (typeof cli._applyGlobalCursor === 'function') ) {
					// force a hard reset
					cli.setGlobalCursor("default");
					cli.setGlobalCursor(null);
					const fnc = cli._applyGlobalCursor;
					cli._org_applyGlobalCursor = fnc;
					cli._applyGlobalCursor = function(value) {
						if ( 'progress' === value || 'wait' === value ) {
							// suppress these cursor shapes
							value = null;
						}
						cli._org_applyGlobalCursor.call(cli, value);
					};
				}
				this.bscMgr = this.cbkWdg.bscMgr;
				this.bscMgr.setBscTmo(args.tos || 0, args.too || 0);
			}
			this.iniDon = true;
		}
	}

	/**
	 * sets the "ignore next request" flag
	 */
	setIgnRqu() {
		this._logRqu('NEXT IGNR');
		this.ignRqu = true;
	}

	/**
	 * initializes "alive ping" timer
	 */
	iniAlvPing(args) {
		const tmo = args.tmo || 0;
		const sck = args.sck || false;
		const self = this;
		const con = rwt.remote.Connection.getInstance();
		if ( (tmo > 0) && con && !this.pingTmh ) {
			this.pingTmh = window.setInterval(() => {
				self._sndAlvPing(con, false);
			}, tmo);
		}
		if ( sck ) {
			self._sndAlvPing(con, true);
		}
	}

	/**
	 * initializes JS logging
	 * @param {*} parameter object
	 */
	initJSLogging(args) {
		// forward this to logging handler
		Logging.getInstance().applyConfiguration(args);
	}

	/**
	 * FileDropHandler: handles a file drop
	 * @see FileDropHandler
	 * @param {File[]} files array of dropped files
	 * @override
	 */
	handleDrop(files) {
		// in fact, we have nothing special to do so far :-)
	}

	/**
	 * FileDropHandler: shows a (warning) message
	 * @see FileDropHandler
	 * @param {String} msg message text
	 * @override
	 */
	showMessage(msg) {
		if ( msg ) {
			this.warn(msg);
		}
	}

	/**
	 * sends a "timer hit" notification
	 * @param {String} name timer name
	 */
	sendTimerHit(name) {
		if ( Validator.isString(name) ) {
			const con = rwt.remote.Connection.getInstance();
			if ( con ) {
				this._sendTimerHit(con, name);
			}
		}
	}

	/**
	 * first time initialization
	 * @param {CliCbkWdg} cbw callback widget
	 */
	_init(cbw) {
		this.cbkWdg = cbw;
		const self = this;
		this._iniBasErrHdl();
		const con = rwt.remote.Connection.getInstance();
		if ( con ) {
			const rtf = con._retry;
			if ( typeof rtf === 'function' ) {
				// ok, hook in
				this.rwtCon = con;
				con._org_retry = rtf;
				con._retry = function() {
					con._org_retry();
					self._nfyConErr();
				};
			}
			// hook-in menu item execution
			let mni_pty = rwt.widgets.MenuItem.prototype;
			let exe_fnc = mni_pty.execute;
			if ( typeof exe_fnc === 'function' ) {
				mni_pty._org_execute = exe_fnc;
				let hook_exe_fnc = function(args) {
					// trigger original method...
					exe_fnc.apply(this, arguments);
					// ... and make sure that every menu is closed right now
					pisasales.ScrMen.static.closeAllMenus();

				}
				mni_pty.execute = hook_exe_fnc;
			}
		}
		// update current display
		const dsp = rwt.widgets.Display.getCurrent();
		if ( dsp ) {
			// hook-in shutdown handler
			const _snd_sht = dsp._sendShutdown;
			if ( typeof _snd_sht === 'function' ) {
				dsp._org_sendShutdown = _snd_sht;
				dsp._sendShutdown = function() {
					self._doSndShtDwn(dsp, con);
				}
			}
			// hook-in focus control
			const _set_foc = dsp.setFocusControl;
			if ( typeof _set_foc === 'function' ) {
				dsp._org_set_foc = _set_foc;
				dsp.setFocusControl = function(idw) {
					try {
						dsp._org_set_foc(idw);
					}
					catch ( e ) {
						self.warn('Failed to set the focus to widget "' + idw + '"!', e);
					}
				}
			}
		}
		// hook-in mouse button work around
		const abp = rwt.remote.EventUtil.addButtonToProperties;
		if ( typeof abp === 'function' ) {
			const wa_abp = function(properties, event) {
				// call original function
				abp(properties, event);
				// make sure that the property "button" is present and an integer
				const btn = properties.button | 0;
				properties.button = btn;
			};
			rwt.remote.EventUtil.addButtonToProperties = wa_abp;
		}
	}

	/**
	 * sends shutdown notification
	 */
	_doSndShtDwn(dsp, con) {
		// destroy the wake lock (if set)
		PSA.getInst().getWakeLock().destroy();
		const self = this;
		let done = false;
		if ( navigator.sendBeacon ) {
			const par = {};
			par.shutdown = true;
			let cid = '';
			if ( con._connectionId ) {
				par.cid = con._connectionId;
				par.shutdown = true;
				cid = '?cid=' + con._connectionId + '&shutdown=true';
				const tgt = 'psashtdwn' + cid;		// we MUST use our own servlet for this, because the default RAP servlet requires JSON data
				const data = JSON.stringify(par);	// Chrome does NOT allow JSON data to be sent here ---> throws error: new Blob([JSON.stringify(par)], {type: 'application/json'});
				if ( navigator.sendBeacon(tgt, data) ) {
					done = true;
				} else {
					self.warn("Browser failed to queue shutdown request!");
				}
			}
		}
		if ( !done ) {
			self.trace(">>> using old school synchronous XMLHttpRequest!");
			dsp._org_sendShutdown();
		}
	}

	/**
	 * sends an "alive ping" request
	 * @param {*} con RAP connection instance
	 * @param {Boolean} sck "set cookie" flag
	 */
	_sndAlvPing(con, sck) {
		const rqu =  new XMLHttpRequest();
		const par = '?cid=' + con._connectionId + '&alvping=true&ssncookie=' + (sck ? 'true' : 'false');
		const url = 'psashtdwn' + par;
		const data = {};
		data.cid = con._connectionId;
		data.alvping = true;
		data.sck = sck || false;
		data.tms = Date.now();
		rqu.open("POST", url, true);
		rqu.setRequestHeader('Content-Type', 'text/plain; charset=UTF-8');
		const self = this;
		rqu.onreadystatechange = function() {
			self._onRquDone(rqu);
		};
		rqu.onerror = function() {
			self._onRquError(rqu);
		};		
		rqu.send(JSON.stringify(data));
	}

	/**
	 * sends a "timer hit" request
	 * @param {*} con RAP connection instance
	 * @param {String} name timer name
	 */
	_sendTimerHit(con, name) {
		const rqu =  new XMLHttpRequest();
		const tname = encodeURI(name);
		const par = `?cid=${con._connectionId}&timerhit=true&tname=${tname}`;
		const url = 'psashtdwn' + par;
		const data = {};
		data.cid = con._connectionId;
		data.timerhit = true;
		data.tname = tname;
		data.tms = Date.now();
		rqu.open("POST", url, true);
		rqu.setRequestHeader('Content-Type', 'text/plain; charset=UTF-8');
		const self = this;
		rqu.onreadystatechange = function() {
			self._onRquDone(rqu);
		};
		rqu.onerror = function() {
			self._onRquError(rqu);
		};
		rqu.send(JSON.stringify(data));
	}

	/**
	 * handles XHR ready state changes
	 * @param {XMLHttpRequest} rqu the XHR request
	 */
	_onRquDone(rqu) {
		if ( rqu.readyState === 4 ) {
			rqu.onreadystatechange = null;
			rqu.abort();
		}
	}
	
	/**
	 * handles XHR errors
	 * @param {XMLHttpRequest} rqu the XHR request
	 */
	_onRquError(rqu) {
		this.warn(`Error in XHR request! Code=${rqu.status}.`);
		this.warn(`Error message: "${xhr.statusText}".`);
	}

	_nfyConErr() {
		if ( this.cbkWdg ) {
			let par = {};
			par.conRst = true;
			this.cbkWdg.nfyGlb("conRst", par, true);
		}
	}
	
	_isDbgLog() {
		return this.isTraceEnabled();
	}

	_incRquCnt() {
		return ++this.rquCnt;
	}
	
	_getRquCnt() {
		return this.rquCnt;
	}

	_logRqu(txt) {
		if ( this._isDbgLog() ) {
			const cnt = ++this.logCnt;
			const now = new Date();
			this.trace(now.toISOString() + ' - CON #' + cnt + ': ' + txt);
		}
	}

	/**
	 * checks the pending messages that the client connection is about to send
	 * @returns {Boolean} true if a block screen should be triggered; false otherwise
	 */
	_chkSndMsg() {
		let show = false;
		if ( this.rwtCon ) {
			const con = this.rwtCon;
			const opa = con._writer._operations;
			if ( opa && (opa.length > 0) ) {
				// check all operations sent to the web server
				const	cnt = opa.length;
				for ( let i=0 ; !show && (i < cnt) ; ++i ) {
					const	opr = opa[i];
					const code = opr[0] || '';			// main code 'set' | 'notify' | 'call' is at index 0
					// we ignore 'set' operations at all - they may not trigger complex operations
					if ( (code === 'notify') || (code === 'call') ) {
						const what = opr[2] || '';		// specific operation code is at index 2
						// we ignore 'FocusIn', 'FocusOut' and 'PSA_NFY_NBS' notifications; everything else is considered to be "block screen" relevant
						show = !((what === 'FocusIn') || (what === 'FocusOut') || (what === 'PSA_NFY_NBS'));
					}
				}
			}
		}
		return show;
	}

	_onSend() {
		this._logRqu('SEND');
		if ( this._chkSndMsg() ) {
			this._showBsc(true);
		} else {
			this._logRqu('IRRELEVANT - block screen request ignored; no relevant messages found.')
		}
	}

	_onRender() {
		this._logRqu('RNDR');
		this._showBsc(false);
	}

	/**
	 * triggers a block screen request, either "show" or "hide"
	 * @param {Boolean} sbc flag whether to trigger a show or hide request of the block screen
	 */
	_showBsc(sbc) {
		let ign = !!this.ignRqu;
		if ( ign ) {
			this._logRqu('IGNR: ' + (sbc ? 'ON' : 'OFF'));
		}
		if ( ign && !sbc ) {
			// reset the "ignore" flag
			this.ignRqu = false;
			if ( this.bscMgr ) {
				ign = !this.bscMgr.isBscVis();
			}
		}
		if ( !ign && this.bscMgr ) {
			this.bscMgr.show(sbc);
		}
	}

	/**
	 * initializes basic error handling
	 */
	_iniBasErrHdl() {
		const self = this;
		if ( typeof rwt.runtime.ErrorHandler.showErrorBox === 'function' ) {
			const erh = rwt.runtime.ErrorHandler;
			erh._org_showErrorBox = rwt.runtime.ErrorHandler.showErrorBox;
			rwt.runtime.ErrorHandler.showErrorBox = function(errorType, freeze, errorDetails) {
				let bsc = self.bscMgr;
				if ( !bsc && self.cbkWdg ) {
					bsc = self.cbkWdg.bscMgr;
				}
				if ( bsc ) {
					bsc.frcOff();
				}
				erh._org_showErrorBox(errorType, freeze, errorDetails);
			}
		}
	}

	/**
	 * initializes extended error handling
	 */
	_iniXtrErrHdl() {
		const self = this;
		const psa = PSA.getInst();
		const psa_err_hdl = function(err) {
			if ( psa.cliCbkWdg ) {
				// ok, *WE* handle this in order to terminate the session gracefully
				const par = {};
				if ( err ) {
					par.info = err.toString();
					if ( err.stack ) {
						par.stack = err.stack;
					}
				} else {
					par.info = 'jsCrash';
				}
				psa.cliCbkWdg.nfyGlb('jsCrash', par, false);
				if ( self.bscMgr ) {
					self.bscMgr.frcOff();
				}
				return true;
			}
			else {
				return false;
			}
		};
		if ( typeof rwt.remote.MessageProcessor._processError === 'function' ) {
			const mpr = rwt.remote.MessageProcessor;
			mpr._org_processError = mpr._processError;
			mpr._processError = function(err, opr) {
				if ( !psa_err_hdl(err) ) {
					// we cannot handle this by ourself
					mpr._org_processError(err, opr);
				}
			};
		}
		// processJavaScriptError : function( error ) {}
		if ( typeof rwt.runtime.ErrorHandler.processJavaScriptError === 'function' ) {
			const erh = rwt.runtime.ErrorHandler;
			erh._org_processJavaScriptError = erh.processJavaScriptError;
			erh.processJavaScriptError = function(err) {
				if ( !psa_err_hdl(err) ) {
					// we cannot handle this by ourself
					erh._org_processJavaScriptError(err);
				}
			};
		}
		// processJavaScriptErrorInResponse : function( script, error, currentRequest ) {}
		if ( typeof rwt.runtime.ErrorHandler.processJavaScriptErrorInResponse === 'function' ) {
			const erh = rwt.runtime.ErrorHandler;
			erh._org_processJavaScriptErrorInResponse = erh.processJavaScriptErrorInResponse;
			erh.processJavaScriptErrorInResponse = function( script, error, currentRequest ) {
				if ( !psa_err_hdl(error) ) {
					// we cannot handle this by ourself
					erh._org_processJavaScriptErrorInResponse(script, error, currentRequest);
				}
			};
		}
		const dnd_hdl = rwt.event.DragAndDropHandler.getInstance();
		if ( dnd_hdl && (typeof dnd_hdl._renderCursor === 'function') ) {
			dnd_hdl._org_renderCursor = dnd_hdl._renderCursor;
			dnd_hdl._renderCursor = function() {
				try {
					dnd_hdl._org_renderCursor();
				}
				catch ( err ) {
					/* *gulp* */
					psa.Log.logErr('Error while drag&drop suppressed!', true);
				}
			}
		}
		if ( typeof rwt.widgets.util.GridCellToolTipSupport.getCurrentToolTipTargetBounds === 'function' ) {
			const grc_ttp = rwt.widgets.util.GridCellToolTipSupport;
			grc_ttp._org_getCurrentToolTipTargetBounds = grc_ttp.getCurrentToolTipTargetBounds;
			grc_ttp.getCurrentToolTipTargetBounds = function(row) {
				try {
					if ( row ) {
						return grc_ttp._org_getCurrentToolTipTargetBounds(row);
					}
				} catch ( e ) {
					/* *gulp* */
					psa.Log.logErr('Error in grid tooltip support suppressed!', true);
				}
				return {
					left : 0,
					top : 0,
					height : 0,
					width : 0
				};
			}
		}
		const mnu_mgr = rwt.widgets.util.MenuManager.getInstance();
		if ( mnu_mgr && (typeof mnu_mgr.update === 'function') ) {
			mnu_mgr._org_update = mnu_mgr.update;
			mnu_mgr.update = function( target, eventType ) {
				try {
					mnu_mgr._org_update(target, eventType);
				} catch ( e ) {
					/* *gulp* */
					psa.Log.logErr('Error in MenuManager.update() suppressed!', true);
				}
			};
		}
	}
}						
