import EditableElement from './EditableElement';
import Validator from '../../../../utils/Validator';
import EventListenerManager from '../../../../utils/EventListenerManager';
import ListenerMgr from '../../../../utils/ListenerMgr';
import TextareaKeyHandlingExtension from './TextareaKeyHandlingExtension';
import MaximizeButtonExtension from './MaximizeButtonExtension';
import CharactersCounterExtension from './CharactersCounterExtension';
import EditingElementSelectionManagerExtension from './EditingElementSelectionManagerExtension';

import HtmHelper from '../../../../utils/HtmHelper';
import DomEventHelper from '../../../../utils/DomEventHelper';
import XCellItem from '../../parts/XCellItem';
import Utils from '../../../../utils/Utils';
import XRowItem from '../../parts/XRowItem';

export default class Textarea extends EditableElement {

	constructor( cellObject ) {
		super( cellObject, 'widgets.xtw.editing.TextArea' );
		cellObject.textarea = this;
		this.lsrManager = new ListenerMgr('widgets.xtw.editing.TextArea');
		this._cce = new CharactersCounterExtension( this );
		new TextareaKeyHandlingExtension( this );
		new MaximizeButtonExtension( this );
		new EditingElementSelectionManagerExtension( this );
	}

	/**
	 * @override
	 */
	getType() {
		return 'textarea';
	}

	get input() {
		return this.textarea;
	}

	get inputValue() {
		if ( !( this.textarea instanceof HTMLElement ) ) {
			return '';
		}
		return this.textarea.value;
	}

	set inputValue( value ) {
		if ( !( this.textarea instanceof HTMLElement ) ) {
			return;
		}
		if ( !Validator.isString( value ) ) {
			value = "";
		}
		this.textarea.value = value;
		this.updateCounterContent();
	}

	get isRendered() {
		return this.container instanceof HTMLElement && this.textarea instanceof HTMLElement;
	}

	get rowElement() {
		const row = this.row;
		if ( !(row instanceof XRowItem) ) {
			return null;
		}
		const rowElement = row.element;
		return rowElement instanceof HTMLElement ? rowElement : null;
	}

	/**
	 * @returns {Number} the current row height
	 */
	get rowHeight() {
		const row = this.row;
		if ( row instanceof XRowItem ) {
			const mi = row.item;
			if ( mi && mi.alive ) {
				return mi.height;
			}
		}
		// return a hard coded default
		return 22;
	}

	get newTextarea() {
		const textarea = window.document.createElement( "textarea" );
		textarea.id = this.inputId;
		textarea.tabIndex = 1;
		this.addTextareaListeners( textarea, this.lsrManager );
		return textarea;
	}

	get newContainer() {
		const textareaDiv = window.document.createElement( "div" );
		textareaDiv.classList.add( "rtp-textarea-container" );
		textareaDiv.tabIndex = 1;
		return textareaDiv;
	}

	setEditingPermission( editingAllowed ) {
		this.editingAllowed = !!editingAllowed;
		if ( !this.editingAllowed && this.textarea instanceof HTMLElement ) {
			this.textarea.readonly = true;
		}
		return this.editingAllowed ? this.markRowAsEdited() : this.resetInput();
	}

	markRowAsEdited() {
		this.rowEdited = true;
	}

	setupRowElement() {
		const rowElement = this.rowElement;
		if ( !( rowElement instanceof HTMLElement ) ) {
			return false;
		}
		rowElement.classList.add( "has-textarea" );
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			return false;
		}
		cellElement.classList.add( "has-textarea" );
		let parentElement = cellElement.parentElement;
		while ( (parentElement instanceof HTMLDivElement) && (parent !== rowElement) ) {
			parentElement.classList.add( "has-textarea" );
			parentElement = parentElement.parentElement;
		}
		return true;
	}

	cleanRowElement() {
		const rowElement = this.rowElement;
		if ( !( rowElement instanceof HTMLElement ) ) {
			return false;
		}
		rowElement.classList.remove( "has-textarea" );
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			return false;
		}
		cellElement.classList.remove( "has-textarea" );
		let parentElement = cellElement.parentElement;
		while ( parentElement instanceof HTMLDivElement && parent != rowElement ) {
			parentElement.classList.remove( "has-textarea" );
			parentElement = parentElement.parentElement;
		}
		return true;
	}

	render() {
		if ( this.shouldBeSkipped ) {
			return false;
		}
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			this.log('Invalid cell element');
			return false;
		}
		const css = window.getComputedStyle(cellElement, null);
		const innerText = cellElement.innerText;
		cellElement.innerHTML = "";
		this.container = this.newContainer;
		this.textarea = this.newTextarea;
		this._setInput(this.textarea);
		this.textarea.innerHTML = innerText;
		const maxCharacterCount = this.maxCharacterCount;
		if ( Validator.isPositiveInteger( maxCharacterCount ) ) {
			this.textarea.maxLength = maxCharacterCount;
		}
		const horizontalAlignment = this.horizontalAlignment;
		if ( Validator.isString( horizontalAlignment ) ) {
			this.textarea.style.textAlign = horizontalAlignment;
		}
		this.button = this.newMinimizeButton;
		this.counter = this._cce.newCharactersCounter;
		this.updateCounterContent( innerText.length );
		this.container.appendChild( this.textarea );
		this.container.appendChild( this.button );
		this.container.appendChild( this.counter );

		const bodyElement = this.xtwBody.element;
		const cellParent = cellElement.parentElement;
		if ( cellParent instanceof HTMLElement ) {
			const cont = this.container;
			const style = cont.style;
			const cell_rect = cellParent.getBoundingClientRect();
			const body_rect = bodyElement.getBoundingClientRect();
			const max_width = Math.max(body_rect.width - 8, 8);
			const max_height = Math.max(body_rect.height - 8, 8);
			const width = Math.min(2 * cell_rect.width, max_width);
			const height = Math.min(3 * this.rowHeight, max_height);
			const max_right = body_rect.width;
			const max_bottom = body_rect.height;
			const cell_right = Math.min(cell_rect.right - body_rect.x, max_right);
			const cell_bottom = Math.min(cell_rect.bottom - body_rect.y, max_bottom);
			let left = Math.max(0, cell_rect.x - body_rect.x);
			let top = Math.max(0, cell_rect.y - body_rect.y);
			if ( (left + width) > max_right ) {
				if ( (cell_right - width) >= 0 ) {
					left = cell_right - width;
				} else {
					left = Math.max(0, max_right - width);
				}
			}
			if ( (top + height) > max_bottom ) {
				if ( (cell_bottom - height) >= 0 ) {
					top = cell_bottom - height;
				} else {
					top = Math.max(0, max_bottom - height);
				}
			}
			style.left = `${left}px`;
			style.top = `${top}px`;
			style.width = `${width}px`;
			style.height = `${height}px`;
			// copy font and color properties
			Utils.forEachProp(css, (name, value) => {
				if ( Validator.isString(value) && !name.includes('border') && (name.includes('font') || name.includes('color')) ) {
					style.setProperty(name, value);
				}
			});
		}
		bodyElement.appendChild(this.container);
		// cellElement.appendChild( this.container );
		if ( this.canBeEdited && this.editingAllowed ) {
			this.register();
		} else {
			this.readonly = true;
		}
		return this.setupRowElement();
	}

	resetInput() {
		if ( !( this.textarea instanceof HTMLElement ) ) {
			return false;
		}
		const originalValue = this.originalValue || "";
		this.textarea.innerHTML = originalValue;
		return true;
	}

	get contentEditableElement() {
		return this.textarea;
	}

	updateCounterContent( contentLength = void 0 ) {
		if ( !( this.counter instanceof HTMLElement ) ) {
			return false;
		}
		if ( !Validator.isPositiveInteger( Number( contentLength ) ) ) {
			contentLength = this._cce.currentContentLength;
		}
		this.counter.innerHTML = this._cce.getCounterContent( contentLength );
		return true;
	}

	discardUi() {
		HtmHelper.rmvDomElm(this.counter);
		delete this.counter;
		const buttonDiscarded = this.discardButton();
		const everythingElseDiscarded = super.discardUi();
		const rowElementCleaned = this.cleanRowElement();
		return buttonDiscarded && everythingElseDiscarded && rowElementCleaned;
	}

	discardButton() {
		if ( this.button instanceof HTMLElement ) {
			[ "mousedown", "mouseup", "click" ].forEach( eventName => {
				EventListenerManager.removeListener( this, eventName, this.button, "ButtonContainer" );
			} );
		}
		return this._discardElementProperty( "button" );
	}

	/**
	 * @override
	 */
	destroySelf() {
		if ( this.alive ) {
			try {
				this.lsrManager.removeAllListeners();
				this.discardUi();
				this.unregister();
				this._cce.destroy();
				delete this._cce;
				const cellObject = this.cell;
				if ( cellObject ) {
					cellObject.textarea = null;
					delete cellObject.textarea;
				}
				this.lsrManager.destroy();
			} finally {
				super.destroySelf();
			}
		}
	}

	discardInput() {
		this.lsrManager.removeAllListeners();
		return super.discardInput();
	}

	informAboutContentChange() {
		if ( !this.editingAllowed || (this.originalValue === this.inputValue) ) {
			return false;
		}
		this.informAboutSave();
		return true;
	}

	/**
	 * ; in fact, this override is a "minimize" (better "restore") operation
	 * @inheritdoc
	 * @override
	 * @param {Event} domEvent 
	 */
	handleMaximizeRequest(domEvent) {
		const cell = this.cell;
		if ( domEvent instanceof Event ) {
			domEvent.stopImmediatePropagation();
		}
		DomEventHelper.stopEvent(domEvent);
		const dirty = this.dirty;
		const value = this.inputValue;
		this.informAboutContentChange();
		this.destroySelf();
		if ( cell instanceof XCellItem ) {
			// we must restore the previous cell editor
			cell.enterEditingMode(null, null, false, dirty);
			if ( cell.inputField instanceof EditableElement ) {
				const input = cell.inputField.input;
				if ( (input instanceof HTMLInputElement) || (input instanceof HTMLTextAreaElement) ) {
					input.value = value;
					input.select();
				}
			}
		}
	}

	onButtonContainerClick( domEvent ) {
		DomEventHelper.stopEvent(domEvent);
		this.handleMaximizeRequest(domEvent);
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} horz 
	 * @param {Boolean} up 
     * @param {KeyboardEvent} ke
	 */
	needsArrowKey(horz, up, ke) {
		// we need *all* keys
		return true;
	}
}
