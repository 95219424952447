import DomEventHelper from '../../../../utils/DomEventHelper';
import EditableElement from './EditableElement';
import EventListenerManager from '../../../../utils/EventListenerManager';
import Validator from '../../../../utils/Validator';
import Textarea from './Textarea';
import MaximizeButtonExtension from './MaximizeButtonExtension';
import CharactersCounterExtension from './CharactersCounterExtension';
import EditingElementSelectionManagerExtension from './EditingElementSelectionManagerExtension';
import BscMgr from '../../../../gui/BscMgr';
import XtwUtils from '../../util/XtwUtils';
import EscHandler from '../../../../key/EscHandler';
import KeyHdl from '../../../../key/KeyHdl';
import HtmHelper from '../../../../utils/HtmHelper';
import XCellItem from '../../parts/XCellItem';

const INPUT_LISTENERS = [ "keydown", "keyup", "keypress", "blur", "mousewheel", "contextmenu", "paste", "change", "focusout", "select", "mousemove" ];
const MAX_LENGTH = 0x7fffffff;

/**
 * internal [Esc] handler class
 */
class InputEscHandler extends EscHandler {

	/**
	 * constructs a new instance
	 * @param {InputField} input the input field
	 */
	constructor(input) {
		super();
		this._input = input;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {KeyboardEvent} evt 
	 */
	handleEsc(evt) {
		// forward to common key listener
		this._input.onInputKeyDown(evt);
		return false;
	}
}


/**
 * common input field class
 */
export default class InputField extends EditableElement {

	constructor( cellObject, ln ) {
		super( cellObject, ln || 'widgets.xtw.editing.InputField' );
		cellObject.inputField = this;
		this._cce = new CharactersCounterExtension( this );
		new MaximizeButtonExtension( this );
		new EditingElementSelectionManagerExtension( this );
		this._escHandler = new InputEscHandler(this);
		this._lastKey = null;
		this._insertMode = false;
	}

	/**
	 * @override
	 */
	getType() {
		return 'input';
	}

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
    get insertMode() {
		return this._insertMode;
    }

	get inputValue() {
		return this.input instanceof HTMLElement ? String(this.input.value) : '';
	}

	get rawCellText() {
		const cellContentObject = this.cellContent;
		return Validator.isObject( cellContentObject ) && Validator.isString( cellContentObject.rawText ) ? cellContentObject.rawText : '';
	}

	get cellText() {
		const cellContentObject = this.cellContent;
		return Validator.isObject( cellContentObject ) && Validator.isString( cellContentObject.text ) ? cellContentObject.text : '';
	}

	get cellInnerText() {
		const cellElement = this.cellElement;
		return cellElement instanceof HTMLElement ? cellElement.innerText : '';
	}

	get contentEditableElement() {
		return this.input;
	}

	get somethingChanged() {
		return this.originalValue !== this.inputValue;
	}

	get insertionDummy() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "insertionDummy" in row ) ) {
			return false;
		}
		return !!row.insertionDummy;
	}

	/**
	 * @override
	 */
	setFocus() {
		if ( this.input instanceof HTMLElement ) {
			this.input.focus();
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	forceBlur(unlock = false) {
		if ( this.alive ) {
			const lc = this.lockCount;
			try {
				if ( unlock ) {
					this.restoreLock(0);
				}
				this.onInputBlur(null);
			} finally {
				if ( unlock ) {
					this.restoreLock(lc);
				}
			}
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	register() {
		super.register();
		KeyHdl.getInstance().addEscHandler(this._escHandler);
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	unregister() {
		KeyHdl.getInstance().rmvEscHandler(this._escHandler);
		super.unregister();
	}

	render() {
		if ( this.shouldBeSkipped ) {
			return false;
		}
		const cell = this.cell;
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			this.warn('Invalid cell element!');
			return false;
		}
		this.discardUi();
		super.render();
		const innerText = this.rawCellText || this.cellInnerText || this.cellText || "";
		cellElement.innerHTML = "";
		this._setInput(this.newInput);
		this.input.value = innerText;
		const show_max = cell.isTextDataType || cell.isNumericDataType;
		this.button = show_max ? this.newMaximizeButton : null;
		this.container = this.newContainer;
		this.container.appendChild( this.input );
		if ( show_max ) {
			this.container.appendChild( this.button );
		}
		cellElement.appendChild( this.container );
		const horizontalAlignment = this.horizontalAlignment;
		if ( Validator.isString( horizontalAlignment ) ) {
			this.input.style.textAlign = horizontalAlignment;
		}
		this._insertMode = this.cell.insertMode;
		if ( !this.insertMode ) {
			this.input.select();
		} else {
			this.input.setSelectionRange(MAX_LENGTH, MAX_LENGTH);
		}
		return true;
	}

	handleMaximizeRequest( domEvent ) {
		const currentValue = this.inputValue;
		const selectionStart = this.selectionStart;
		const selectionEnd = this.selectionEnd;
		const selectionDirection = this.selectionDirection;
		const cell = this.cell;
		this.destroySelf();
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.createAndFocusTextarea ) ) {
			return false;
		}
		cell.createAndFocusTextarea();
		if ( !( cell.textarea instanceof Textarea ) ) {
			return false;
		}
		cell.textarea.inputValue = currentValue;
		cell.textarea.setSelection( selectionStart, selectionEnd, selectionDirection );
		return true;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
	informAboutContentChange() {
		if ( this.alive ) {
			this.log(`Processing content change of editable element "${this.instanceID}":`);
			if ( this.editingAllowed && (this.originalValue !== this.inputValue) ) {
				this.log(`---> sending "${this.inputValue}"...`);
				this.informAboutSave();
				return true;
			} else {
				this.log(`---> unchanged!`);
				return false;
			}
		}
		return false;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} editingAllowed 
	 * @returns {Boolean}
	 */
	setEditingPermission( editingAllowed ) {
		this.editingAllowed = !!editingAllowed;
		return this.editingAllowed ? this.markRowAsEdited() : this.resetInput();
	}

	markRowAsEdited() {
		this.rowEdited = true;
		return true;
	}

	resetInput() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		const originalValue = this.originalValue || "";
		this.input.value = originalValue;
		return true;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {*} args 
	 * @returns 
	 */
	syncInputContentWithDropdown( args ) {
		if ( !this.editingAllowed || !Validator.isObject( args ) ||
			!Validator.isString( args.content ) ||
			!( this.input instanceof HTMLElement ) || !this.canBeEdited ) {
			return false;
		}
		this.input.innerHTML = args.content;
		this.input.value = args.content;
		this.dirty = true;
		this.setEditingPermission( true );
		this.selectAndFocusEndOfInput();
		if ( args.registerInputFocusCallback === true ||
			Validator.isTrue( args.registerInputFocusCallback ) ) {
			return this.registerInputFocusCallback();
		}
		return true;
	}

	registerInputFocusCallback() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isFunctionPath( xtwBody, "xtwBody.setupEnsuingModelDataCallback" ) ) {
			return false;
		}
		const cell = this.cell;
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		const instance = this;
		return xtwBody.setupEnsuingModelDataCallback(
			"registerInputFocusCallback-", () => {
				if ( !Validator.isFunctionPath( cell, cell.createAndFocusInputField ) ) {
					return false;
				}
				instance.destroy();
				if ( Validator.isFunctionPath( cell, "cell.focusCell" ) ) {
					cell.focusCell();
				}
				cell.createAndFocusInputField();
				let success = false;
				if ( Validator.isFunctionPath( cell, "cell.inputField.selectAndFocusEndOfInput" ) ) {
					success = cell.inputField.selectAndFocusEndOfInput();
				}
				return success;
				// return instance.selectAndFocusEndOfInput();
			} );
	}

	selectAndFocusEndOfInput() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		const selectionEnd = this.selectionEnd;
		this.setSelection( selectionEnd, selectionEnd );
		this.input.focus();
		return true;
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	destroySelf() {
		if ( this.alive ) {
			try {
				this.discardUi();
				this.unregister();
				const cellObject = this.cell;
				if ( Validator.isObject( cellObject ) ) {
					cellObject.inputField = null;
					delete cellObject.inputField;
				}
				this._cce.destroy();
				delete this._cce;
			} finally {
				delete this._escHandler;
				super.destroySelf();
			}
		}
	}

	discardUi() {
		const buttonDiscarded = this.discardButton();
		const everythingElseDiscarded = super.discardUi();
		return buttonDiscarded && everythingElseDiscarded;
	}

	discardButton() {
		if ( this.button instanceof HTMLElement ) {
			[ "mousedown", "mouseup", "click" ].forEach( eventName => {
				EventListenerManager.removeListener( this, eventName, this.button, "ButtonContainer" );
			} );
		}
		return this._discardElementProperty( "button" );
	}

	discardInput() {
		const input = this.input;
		if ( input instanceof HTMLElement ) {
			INPUT_LISTENERS.forEach( eventName => {
				EventListenerManager.removeListener( this, eventName, input, "Input" );
			} );
		}
		return super.discardInput();
	}

	get newInput() {
		const pwd = this.isPassword;
		const cbm = !pwd && this.cell.column.contentBreakMode;
		const can_break = cbm && !!cbm.canBreak;
		let ie = null;
		if ( can_break ) {
			// multi-line text area element
			const textarea = document.createElement('textarea');
			textarea.className = 'xtw-cell-edit';
			textarea.wrap = cbm.mode === 'breakline' ? 'off' : 'soft';
			ie = textarea;
		} else {
			// single line input element
			const input = window.document.createElement( "input" );
			input.type = pwd ? 'password' : 'text';
			ie = input;
		}
		ie.id = this.inputId;
		ie.tabIndex = 1;
		this.addBasicListeners( ie );
		return ie;
	}

	/**
	 * this method needs to be defined on the prototype of the object and not on
	 * any of its extensions, because a subclass is overwriting this method and
	 * using a reference to this prototype (super)
	 * @param {Event} the "blur" event
	 */
	onInputBlur( domEvent ) {
		if ( !this.alive ) {
			return false;
		}
		if ( !this.locked ) {
			// this.log(`${this.instanceID}: Focus lost - self-destruction.`);
			const self = this;
			return BscMgr.getInstance().runWithLockedFocus(self, () => {
				return self._destroyOnBlur(domEvent);
			});
		} else {
			// this.log(`${this.instanceID}: Focus locked - doing nothing.`);
			return false;
		}
	}

	_destroyOnBlur(domEvent) {
		let do_send = true;
		if ( domEvent instanceof Event ) {
			const target = domEvent.relatedTarget;
			if ( HtmHelper.isCancelBtn(target) ) {
				// do *not* send changed data
				do_send = false;
			}
		}
		if ( do_send ) {
			this.informAboutContentChange();
		}
		return this.destroySelfAndRestoreCell();
	}

	onInputFocusout( domEvent ) {
		return this.onInputBlur( domEvent );
	}

	onInputChange( domEvent ) {
		// return this.informAboutContentChange();
	}

	onInputSelect( domEvent ) {
		// this is solely to prevent drag events on table level that might cause
		// adding a new row
		return DomEventHelper.stopIf( domEvent, true, false );
	}

	onInputMousemove( domEvent ) {
		// this is solely to prevent drag events on table level that might cause
		// adding a new row
		return DomEventHelper.stopIf( domEvent, true, false );
	}

	/**
	 * @override
	 */
	 onInputEnter( domEvent ) {
		const cell = this.cell;
		this.informAboutContentChange();
		this.destroySelfAndRestoreCell();
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputEnter ) ) {
			return false;
		}
		return cell.onInputEnter( domEvent );
	}

	/**
	 * @override
	 */
	onInputTab( domEvent ) {
		const cell = this.cell;
		this.informAboutContentChange();
		this.destroySelfAndRestoreCell();
		if ( cell instanceof XCellItem ) {
			return cell.onInputTab( domEvent );
		}
		return false;
	}

	/**
	 * @override
	 */
	 onInputEscape( domEvent ) {
		const cell = this.cell;
		if ( this.somethingChanged ) {
			this.informAboutCancel();
		}
		this.destroySelfAndRestoreCell();
		if ( !Validator.isObject( cell ) || !Validator.isFunction( cell.onInputEscape ) ) {
			return false;
		}
		return cell.onInputEscape( domEvent );
	}

	/**
	 * this method needs to be defined on the prototype of the object and not on
	 * any of its extensions, because a subclass is overwriting this method and
	 * using a reference to this prototype (super)
	 * @param {KeyboardEvent} domEvent the keyboard event
	 */
	onVerticalArrowKeyDown( domEvent ) {
		// const row = this.row;
		if ( DomEventHelper.isCtrlEvent(domEvent) || DomEventHelper.isShiftEvent(domEvent) ) {
			// never ever handle here!
			return;
		}
		const up = DomEventHelper.keyIs( domEvent, 'ArrowUp' ) ;
		if ( !this.needsArrowKey(false, up, domEvent) ) {
			this.informAboutContentChange();
		}
	}

	onButtonContainerClick( domEvent ) {
		DomEventHelper.stopEvent(domEvent);
		this.handleMaximizeRequest(domEvent);
	}

	/**
	 * handles "keydown" events
	 * @param {KeyboardEvent} domEvent the keyboard event
	 * @returns 
	 */
	 onInputKeyDown( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.inputId = this.inputId;
			this._lastKey = domEvent.key;
		}
		if ( DomEventHelper.keyIs( domEvent, "Tab" ) ) {
			return this.onInputTab( domEvent );
		}
		if ( XtwUtils.isArrowLeft( domEvent ) || XtwUtils.isArrowRight( domEvent ) ) {
			domEvent.stopPropagation();
			return true;
		}
		if ( DomEventHelper.keyIs( domEvent, "ArrowUp" ) || DomEventHelper.keyIs( domEvent, "ArrowDown" ) ) {
			return this.onVerticalArrowKeyDown( domEvent );
		}
		if ( DomEventHelper.isSaveEvent( domEvent ) ) {
			return this.saveAllAndKeepFocus( domEvent );
		}
		if ( XtwUtils.isContentChangingKey( domEvent ) ) {
			return this.handleContentChangingKeyDown( domEvent );
		}
	}

	onInputKeyUp( domEvent ) {
		try {
			if ( domEvent instanceof KeyboardEvent ) {
				domEvent.inputId = this.inputId;
				if ( !Validator.isString(this._lastKey) || (this._lastKey !== domEvent.key) ) {
					DomEventHelper.stopEvent(domEvent);
					this.trace(`keyup event "${domEvent.key}" discarded. No matching keydown recorded.`);
					return false;
				}
			}
			if ( XtwUtils.keyIs( domEvent, "Enter" ) ) {
				return this.onInputEnter( domEvent );
			}
			if ( XtwUtils.keyIs( domEvent, "Escape" ) ) {
				return this.onInputEscape( domEvent );
			}
			if ( XtwUtils.isContentChangingKey( domEvent ) ) {
				return this.handleContentChangingKeyUp( domEvent );
			}
			if ( XtwUtils.keyIs( domEvent, "F2" ) ) {
				return this.handleMaximizeRequest( domEvent );
			}
			return true;
		} finally {
			this._lastKey = null;
		}
	}

	onInputKeyPress( domEvent ) {
		if ( this.isReadOnly ) {
			domEvent.preventDefault();
			return true;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.inputId = this.inputId;
		}
		if ( this.dirtyInsertionDummy ) {
			DomEventHelper.stopEvent( domEvent );
			return true;
		}
		return true;
	}

	onInputContextMenu( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.inputId = this.inputId;
		}
	}

	onInputPaste( domEvent ) {
		const current = this.inputValue;
		const pastedContent = DomEventHelper.getClipboardDataTransferText( domEvent );
		if ( !Validator.isString( pastedContent ) ) {
			return false;
		}
		if ( !this.canBeEdited || !this.editingAllowed || (this._cce.allowedInsertionLength < pastedContent.length) ) {
			if ( domEvent instanceof Event ) {
				domEvent.stopImmediatePropagation();
			}
			DomEventHelper.stopEvent(domEvent);
		} else {
			if ( this.isTraceEnabled() ) {
				this.trace(`Got pasted clipboard data "${pastedContent}". Trigger processing...`);
			}
			const self = this;
			const instanceID = self.instanceID;
			setTimeout(() => {
				if ( self.alive ) {
					self._handleInputPaste(0, current, pastedContent);
				} else {
					console.warn(`Instance "${instanceID}" not longer alive!`);
				}
			}, 0);
		}
		return true;
	}

	/**
	 * processes clipboard paste events
	 * @param {Number} depth recursion depth
	 * @param {String} before the content before the paste operation
	 * @param {String} pasted the pasted content
	 */
	_handleInputPaste(depth, before, pasted) {
		if ( this.alive ) {
			if ( this.isTraceEnabled() ) {
				this.trace(`Processing pasted clipboard data "${pasted}".`);
			}
			if ( this.canBeEdited && this.editingAllowed ) {
				const value = this.inputValue;
				if ( !value.includes(pasted) ) {
					// oops?!
					this.trace(`Current content "${value}" does not yet contain pasted clipboard data.`);
					if ( depth === 0 ) {
						// try one more time
						this.trace('Triggering another cycle...');
						const self = this;
						setTimeout(() => {
							if ( self.alive ) {
								self._handleInputPaste(depth + 1, before, pasted);
							}
						}, 25);
						return;
					} else {
						this.trace('Second attempt to process clipboard data may fail. Giving up.');
					}
				}
				if ( !this.dirty ) {
					this.dirty = true;
					this.informAboutEditing();
				}
				this.informAboutContentChange();
			} else {
				if ( this.isTraceEnabled() ) {
					this.trace(`Editing not allowed. Resetting content to "${before}".`);
				}
				const input = this.input;
				if ( (input instanceof HTMLInputElement) || (input instanceof HTMLTextAreaElement) ) {
					input.value = Validator.ensureString(before);
				}
			}
		}
	}

	onInputScroll( domEvent ) {
		this.informAboutContentChange();
		return this.destroySelfAndRestoreCell();
	}

	onButtonContainerClick( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		return this.handleMaximizeRequest( domEvent );
	}

	handleContentChangingKeyUp( domEvent ) {
		if ( DomEventHelper.isSelectAllEvent( domEvent ) ) {
			if ( this.input instanceof HTMLElement ) {
				this.input.select();
			}
			return true;
		}
		if ( DomEventHelper.isContentKeepingControlEvent( domEvent ) ) {
			return true;
		}
		if ( this.dirtyInsertionDummy ) {
			DomEventHelper.stopIf( domEvent );
			return true;
		}
		if ( this.dirty || this.originalValue === this.inputValue ) {
			return true;
		}
		this.informAboutEditing();
		this.dirty = true;
		return true;
	}

	handleContentChangingKeyDown( domEvent ) {
		if ( DomEventHelper.isContentKeepingControlEvent( domEvent ) ) {
			return true;
		}
		if ( this.dirtyInsertionDummy ) {
			return DomEventHelper.stopIf( domEvent );
		}
		if ( !this.canBeEdited || !this.editingAllowed || this._cce.eventSurpassesMaximumCharacterCount( domEvent ) ) {
			if ( domEvent instanceof Event ) {
				domEvent.preventDefault();
			}
			return false;
		}
		return true;
	}

	addBasicListeners( input ) {
		const keydownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onInputKeyDown",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onInputKeyUp",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const keypressListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keypress",
			functionName: "onInputKeyPress",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const contextmenuListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "contextmenu",
			functionName: "onInputContextMenu",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const pasteListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "paste",
			functionName: "onInputPaste",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const changeListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "change",
			functionName: "onInputChange",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const selectListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "select",
			functionName: "onInputSelect",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const mousemoveListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousemove",
			functionName: "onInputMousemove",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		return keydownListenerSuccessfullyAdded && keyupListenerSuccessfullyAdded &&
			keypressListenerSuccessfullyAdded && contextmenuListenerSuccessfullyAdded &&
			pasteListenerSuccessfullyAdded && changeListenerSuccessfullyAdded &&
			selectListenerSuccessfullyAdded && mousemoveListenerSuccessfullyAdded;
	}

	addBlurListener() {
		const blurListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "blur",
			functionName: "onInputBlur",
			callBackPrefix: "Input",
			element: this.input,
			useCapture: false
		} );
		const focusoutListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "focusout",
			functionName: "onInputFocusout",
			callBackPrefix: "Input",
			element: this.input,
			useCapture: false
		} );
		if ( blurListenerSuccessfullyAdded && focusoutListenerSuccessfullyAdded ) {
			delete this.addBlurListener;
		}
		return blurListenerSuccessfullyAdded;
	}

	addMouseWheelListener() {
		const mousewheelListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "mousewheel",
				functionName: "onInputScroll",
				callBackPrefix: "Input",
				element: this.input,
				useCapture: false
			} );
		const wheelListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "wheel",
				functionName: "onInputScroll",
				callBackPrefix: "Input",
				element: this.input,
				useCapture: false
			} );
		if ( mousewheelListenerSuccessfullyAdded && wheelListenerSuccessfullyAdded ) {
			delete this.addMouseWheelListener;
		}
		return mousewheelListenerSuccessfullyAdded && wheelListenerSuccessfullyAdded ;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {KeyboardEvent} domEvent 
	 * @returns {Boolean}
	 */
	saveAllAndKeepFocus( domEvent ) {
		DomEventHelper.stopEvent(domEvent);
		this.informAboutContentChange();
		this.informAboutFullSave();
		this.destroySelfAndRestoreCell();
		return true;
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} horz 
	 * @param {Boolean} up 
     * @param {KeyboardEvent} ke
	 */
	needsArrowKey(horz, up, ke) {
		const input = this.input;
		if ( horz ) {
			// I always need this!
			return true;
		} else {
			if ( input instanceof HTMLTextAreaElement ) {
				this.trace('needs arrow for text area');
				// multi-line field - needs vertical arrow keys, too
				return true;
			} else {
				// I don't need this - ask super
				return super.needsArrowKey(horz, up, ke);
			}
		}
	}
}

export { INPUT_LISTENERS };