import EventListenerManager from '../../../utils/EventListenerManager';
import HtmHelper from '../../../utils/HtmHelper';
import ListenerItem from '../../../utils/ListenerItem';

/**
 * generic UI item
 */
export default class XItem extends ListenerItem {

	/**
	 * constructs a new instance
	 * @param {String} logger name
	 */
	constructor(ln) {
		super(ln || 'widgets.xtw.XItem');
		this.element = document.createElement( 'div' );
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	doDestroy() {
		this.removeAllListeners();
		HtmHelper.rmvDomElm(this.element);
		delete this.element;
		super.doDestroy();
	}

	/**
	 * returns the main element of this UI item
	 * @returns {HTMLElement} the DOM element of this UI item
	 */
	getDomElement() {
		return this.element;
	}

	/**
	 * gets & returns the "rendered" status of this item, which is true when this
	 * item has a valid HTML element and false otherwise
	 * @return {Boolean} true if element is present & valid, false otherwise
	 */
	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	/**
	 * dummy method
	 */
	onClick( evt, parameters = void 0 ) {}

	/**
	 * adds a "click" event listener to this item's HTML element, if such exists
	 * @see XtwRtpItm.js~EventListenerManager
	 */
	addClickListener( element = void 0 ) {
		return this.addListener( "click", "onClick", element );
	}

	/**
	 * removes the "click" event listener from this item's HTML element if the
	 * element is valid/rendered and the event listener exists (was previously
	 * added)
	 * @see XtwRtpItm.js~EventListenerManager
	 */
	removeClickListener( element = void 0 ) {
		return this.removeListener( "click", element );
	}

}
