import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import AttachmentObject from '../../../../utils/AttachmentObject';
import XtwUtils from '../../util/XtwUtils';

export default class ColumnManagementExtension extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
		this.assignGettersAndSettersTo( hostPlugin );
		Validator.unmodifiableGetter( {
			hostObject: hostPlugin,
			getterName: "orderedColumns",
			getCallback: () => {
				if ( !Validator.isObject( hostPlugin.ordCol ) ) {
					return void 0;
				}
				const fixedColumns = !Validator.isArray( hostPlugin.ordCol.fix ) ? [] :
					Array.from( hostPlugin.ordCol.fix ).filter( column =>
						Validator.is( column, "XtwCol" ) &&
						Validator.isPositiveNumber( column.id ) );
				const dynamicColumns = !Validator.isArray( hostPlugin.ordCol.dyn ) ? [] :
					Array.from( hostPlugin.ordCol.dyn ).filter( column =>
						Validator.is( column, "XtwCol" ) &&
						Validator.isPositiveNumber( column.id ) );
				return fixedColumns.concat( dynamicColumns );
			}
		} );
	}

	get currentlyVisibleColumns() {
		const orderedColumns = this.orderedColumns;
		if ( !Validator.isArray( orderedColumns, true ) ) {
			return [];
		}
		return orderedColumns.filter( column => Validator.isObject( column ) && column.visible );
	}

	get firstVisibleColumn() {
		const currentlyVisibleColumns = this.currentlyVisibleColumns;
		return currentlyVisibleColumns.length > 0 ? currentlyVisibleColumns[ 0 ] : void 0;
	}

	get lastVisibleColumn() {
		const currentlyVisibleColumns = this.currentlyVisibleColumns;
		return currentlyVisibleColumns.length > 0 ? currentlyVisibleColumns[ currentlyVisibleColumns.length - 1 ] : void 0;
	}

	get firstVisibleColumnId() {
		const firstVisibleColumn = this.firstVisibleColumn;
		return Validator.isObject( firstVisibleColumn ) ? firstVisibleColumn.id : void 0;
	}

	get lastVisibleColumnId() {
		const lastVisibleColumn = this.lastVisibleColumn;
		return Validator.isObject( lastVisibleColumn ) ? lastVisibleColumn.id : void 0;
	}

	isValidRenderedMovableColumn( column ) {
		return Validator.is( column, "XtwCol" ) && column.element instanceof HTMLElement && Validator.isPositiveInteger( column.id );
	}

	getColumn( columnId ) {
		columnId = Number( columnId );
		if ( !Validator.isPositiveInteger( columnId ) ) {
			return void 0;
		}
		if ( !Validator.is( this.columns, "ObjReg" ) ) {
			return this._getOrderedColumn( columnId );
		}
		let column = this.columns.getObj( columnId );
		if ( Validator.is( column, "XtwCol" ) ) {
			return column;
		}
		if ( !Validator.isMap( this.columns._objReg ) ) {
			return this._getOrderedColumn( columnId );
		}
		column = this.columns._objReg.get( `o${ columnId }` );
		if ( Validator.is( column, "XtwCol" ) ) {
			return column;
		}
		return this._getOrderedColumn( columnId );
	}

	_getOrderedColumn( columnId ) {
		columnId = Number( columnId );
		if ( !Validator.isPositiveInteger( columnId ) ) {
			return void 0;
		}
		if ( !Validator.isObject( this.ordCol ) ) {
			return void 0;
		}
		if ( Validator.isArray( this.ordCol.fix, true ) ) {
			const column = this.ordCol.fix.find( fixedColumn =>
				Validator.is( fixedColumn, "XtwCol" ) && fixedColumn.id == columnId );
			if ( Validator.isObject( column ) ) {
				return column;
			}
		}
		if ( !Validator.isArray( this.ordCol.dyn, true ) ) {
			return void 0;
		}
		const column = this.ordCol.dyn.find( dynamicColumn =>
			Validator.is( dynamicColumn, "XtwCol" ) && dynamicColumn.id == columnId );
		return Validator.isObject( column ) ? column : void 0;
	}

	moveOneColumnBeforeTheOther( oneColumn, otherColumn, alsoMoveInBody = true ) {
		if ( [ oneColumn, otherColumn ].some( column => (
				!Validator.is( column, "XtwCol" ) ||
				!Validator.isPositiveInteger( column.id ) ||
				!( column.element instanceof HTMLElement ) ) ) ||
			oneColumn === otherColumn ) {
			return false;
		}
		// step 1:
		this.moveFirstColumnBeforeSecond( oneColumn, otherColumn );
		// step 2:
		if ( alsoMoveInBody && Validator.is( this.xtwBody, "XtwBody" ) ) {
			this.xtwBody.moveFirstColumnBeforeSecond( oneColumn, otherColumn );
		}
		// step 3:
		this.updateColumnOrder( oneColumn, otherColumn );
		// step 4:
		oneColumn.fix = otherColumn.fix;
		oneColumn.element.__fix = otherColumn.fix;
		return true;
	}

	switchWithColumnAtPosition( {
		columnToBeSwitched,
		columnToBeSwitchedId,
		position,
		alsoSwitchInBody = true
	} ) {
		if ( !this.isValidRenderedMovableColumn( columnToBeSwitched ) ) {
			columnToBeSwitched = this.getColumn( columnToBeSwitchedId );
			if ( !this.isValidRenderedMovableColumn( columnToBeSwitched ) ) {
				return false;
			}
		}
		const orderedColumns = this.orderedColumns;
		if ( !Validator.isArray( orderedColumns ) ||
			orderedColumns.length < position + 1 ) {
			return false;
		}
		const columnAtPosition = orderedColumns[ position ];
		if ( !this.isValidRenderedMovableColumn( columnAtPosition ) ) {
			return false;
		}
		if ( columnAtPosition === columnToBeSwitched ) {
			return true;
		}
		return this.swapColumnsPositions( columnToBeSwitched, columnAtPosition, alsoSwitchInBody );
	}

	swapColumnsPositions( firstColumn, secondColumn, alsoSwapInBody = true ) {
		if ( [ firstColumn, secondColumn ].some( column =>
				!this.isValidRenderedMovableColumn( column ) ) ) {
			return false;
		}
		if ( firstColumn === secondColumn ) {
			return true;
		}
		// step 1
		if ( !this.swapElements( firstColumn.element, secondColumn.element ) ) {
			// TODO log/warn
			return false;
		}
		// step 2
		if ( !this.adjustWidthsAfterSwappingColumns( firstColumn, secondColumn ) ) {
			// TODO log/warn
			return false;
		}
		if ( alsoSwapInBody && Validator.is( this.xtwBody, "XtwBody" ) ) {
			// TODO
			// this.xtwBody.swapColumnsPositions( firstColumn, secondColumn );
		}

		// step 3
		this.updateColumnOrderAfterSwapping( firstColumn, secondColumn );

		// step 4
		if ( !this.updateColumnsFixStates( firstColumn, secondColumn ) ) {
			// TODO log/warn
			return false;
		}
	}

	updateColumnOrderAfterSwapping( firstColumn, secondColumn ) {
		// TODO
	}

	updateColumnsFixStates( firstColumn, secondColumn ) {
		if ( [ firstColumn, secondColumn ].some(
				column => !Validator.is( column, "XtwCol" ) ) ) {
			return false;
		}
		if ( firstColumn.fix === secondColumn.fix ) {
			return true;
		}
		let bothColumnsFixedStatesWereUpdated = true;
		[ firstColumn, secondColumn ].forEach( column => {
			if ( this.toggleFixState( column ) ) {
				return;
			}
			bothColumnsFixedStatesWereUpdated = false;
		} );
		return bothColumnsFixedStatesWereUpdated;
	}

	toggleFixState( column ) {
		if ( !Validator.is( column, "XtwCol" ) ) {
			return false;
		}
		column.fix = !column.fix;
		if ( !( column.element instanceof HTMLElement ) ) {
			return false;
		}
		column.element.__fix = column.fix;
		return true;
	}

	adjustWidthsAfterSwappingColumns( firstColumn, secondColumn ) {
		if ( [ firstColumn, secondColumn ].some(
				column => !Validator.is( column, "XtwCol" ) ) ||
			firstColumn === secondColumn ) {
			return false;
		}
		if ( firstColumn.fix === secondColumn.fix ) {
			return true;
		}
		const itemManager = window.pisasales.getItmMgr();
		if ( !Validator.is( itemManager, "ItmMgr" ) ) {
			return false;
		}
		const widthDifference = firstColumn.width - secondColumn.width;
		const fixedWidthDifference = widthDifference * ( firstColumn.fix ? -1 : 1 );
		const dynamicWidthDifference = -1 * fixedWidthDifference;
		return this.adjustFixedAndDynamicWidths( fixedWidthDifference, dynamicWidthDifference );
	}

	swapElements( firstElement, secondElement ) {
		if ( [ firstElement, firstElement.parentElement,
				secondElement, secondElement.parentElement
			].some( element => !( element instanceof HTMLElement ) ) ||
			firstElement === secondElement ) {
			return false;
		}
		const firstElementParent = firstElement.parentElement;
		const secondElementParent = secondElement.parentElement;
		const firstElementSibling = firstElement.nextElementSibling;
		const secondElementSibling = secondElement.nextElementSibling;
		if ( firstElementSibling instanceof HTMLElement ) {
			firstElementParent.insertBefore( secondElement, firstElementSibling );
		} else {
			firstElementParent.appendChild( secondElement );
		}
		if ( secondElementSibling instanceof HTMLElement ) {
			secondElementParent.insertBefore( firstElement, secondElementSibling );
		} else {
			secondElementParent.appendChild( firstElement );
		}
		return true;
	}

	moveColumnToPosition( { column, columnId, position, alsoMoveInBody = true } ) {
		if ( !this.isValidRenderedMovableColumn( column ) ) {
			column = this.getColumn( columnId );
			if ( !this.isValidRenderedMovableColumn( column ) ) {
				return false;
			}
		}
		const orderedColumns = this.orderedColumns;
		const currentPosition = Validator.getIndexInArray( orderedColumns, column );
		if ( !Validator.isPositiveInteger( currentPosition ) ) {
			return false;
		}
		if ( currentPosition === position ) {
			return true;
		}
		if ( position == orderedColumns.length - 1 ) {
			const otherColumn = orderedColumns[ orderedColumns.length - 1 ];
			this.moveOneColumnBeforeTheOther( column, otherColumn, alsoMoveInBody );
			return this.moveOneColumnBeforeTheOther( otherColumn, column, alsoMoveInBody );
		}
		const positionAfter = position + 1;
		const otherColumn = orderedColumns[ positionAfter ];
		return this.moveOneColumnBeforeTheOther( column, otherColumn, alsoMoveInBody );
	}

	/**
	 * moves the title cell (precisely its HTML element) from the first column
	 * to a position exactly after the corresponding (second) title cell in the
	 * second column
	 * @param {XtwCol} firstColumn the first column (the one whose title cell
	 * should be moved and should change its place)
	 * @param {XtwCol} secondColumn the second column (the one whose title cell
	 * should keep its place)
	 * @return {Boolean} true if the movement was successfull, false otherwise
	 */
	moveFirstColumnAfterSecond( firstColumn, secondColumn ) {
		return this.moveFirstColumnBeforeSecond( secondColumn, firstColumn );
	}

	/**
	 * moves the title cell (precisely its HTML element) from the first column
	 * to a position exactly before the corresponding (second) title cell in the
	 * second column; also changes/adjusts the fixed and dynamic cell containers'
	 * widths in the case/scenario when the title cell element is moved from a
	 * fixed container to a dynamic one or viceversa
	 * @param {XtwCol} firstColumn the first column (the one whose title cell
	 * should be moved and should change its place)
	 * @param {XtwCol} secondColumn the second column (the one whose title cell
	 * should keep its place)
	 * @return {Boolean} true if the movement was successfull, false otherwise
	 */
	moveFirstColumnBeforeSecond( firstColumn, secondColumn ) {
		if ( [ firstColumn, secondColumn ].some( column => (
				!Validator.is( column, "XtwCol" ) ||
				!( column.element instanceof HTMLElement ) ) ) ||
			firstColumn === secondColumn ||
			!( secondColumn.element.parentElement instanceof HTMLElement ) ) {
			return false;
		}
		secondColumn.element.parentElement
			.insertBefore( firstColumn.element, secondColumn.element );
		if ( firstColumn.fix === secondColumn.fix ) {
			return true;
		}
		const fixedWidthDifference = firstColumn.width * ( firstColumn.fix ? -1 : 1 );
		const dynamicWidthDifference = -1 * fixedWidthDifference;
		return this.adjustFixedAndDynamicWidths( fixedWidthDifference, dynamicWidthDifference );
	}

	adjustFixedAndDynamicWidths( fixedWidthDifference, dynamicWidthDifference ) {
		const itemManager = window.pisasales.getItmMgr();
		if ( !Validator.is( itemManager, "ItmMgr" ) ) {
			return false;
		}
		if ( Validator.isValidNumber( fixedWidthDifference ) ) {
			this.wdtFix += fixedWidthDifference;
			itemManager.setFlexWdt( this.ccnFix, this.wdtFix, true );
			XtwUtils.syncZeroWidthClass( this.ccnFix, this.wdtFix );
		}
		if ( Validator.isValidNumber( dynamicWidthDifference ) ) {
			this.wdtDyn += dynamicWidthDifference;
			itemManager.setFlexWdt( this.ccnDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.ccnDyn, this.wdtDyn );
		}
		return true;
	}

	updateColumnOrder( movedColumn, targetColumn ) {
		const updatedInOrderedColumns =
			this.updateColumnOrderInOrderedColumns( movedColumn, targetColumn );
		const updatedInColumns =
			this.updateColumnOrderInColumns( movedColumn, targetColumn );
		return updatedInOrderedColumns && updatedInColumns;
	}

	updateColumnOrderInOrderedColumns( movedColumn, targetColumn ) {
		if ( [ movedColumn, targetColumn ].some( column =>
				!Validator.is( column, "XtwCol" ) ) ) {
			return false;
		}
		const removedFromArray = Validator.removeElementFromArray(
			this.ordCol[ ( !!movedColumn.fix ? "fix" : "dyn" ) ], movedColumn );
		Warner.traceIf( !removedFromArray, `Could not remove the moved column" +
			" from its original array in the "ordered Columns".` )
		const targetArray = targetColumn.fix ? this.ordCol.fix : this.ordCol.dyn;
		if ( !Validator.isArray( targetArray ) ) {
			Warner.traceIf( true, `There is no valid array in the "ordered` +
				` Columns" for the target column before which the moved column` +
				` should be placed` );
			return false;
		}
		const targetIndex = Validator.getIndexInArray( targetArray, targetColumn );
		if ( !Validator.isPositiveInteger( targetIndex ) ||
			targetIndex >= targetArray.length ) {
			// todo log
			return false;
		}
		targetArray.splice( targetIndex, 0, movedColumn );
		return true;
	}

	updateColumnOrderInColumns( movedColumn, targetColumn ) {
		if ( [ movedColumn, targetColumn ].some( column =>
				!Validator.is( column, "XtwCol" ) ) ) {
			return false;
		}
		if ( !Validator.is( this.columns, "ObjReg" ) ||
			!Validator.isMap( this.columns._objReg ) ) {
			return false;
		}
		const regId = this.columns._getId( movedColumn.id );
		if ( this.columns._objReg.has( regId ) ) {
			this.columns._objReg.delete( regId );
		} else {
			// todo log
		}
		const columns = [ ...this.columns._objReg.values() ];
		const targetIndex = Validator.getIndexInArray( columns, targetColumn );
		if ( !Validator.isPositiveInteger( targetIndex ) ||
			targetIndex >= columns.length ) {
			// todo log
			return false;
		}
		columns.splice( targetIndex, 0, movedColumn );
		this.columns._objReg.clear();
		for ( let column of columns ) {
			this.columns.addObj( column.id, column );
		}
		return true;
	}

	resetColumnsDefaultProperties( orderedColumnsProperties ) {
		if ( !Validator.isObject( orderedColumnsProperties ) ) {
			return false;
		}
		return this.resetColumnsWidthsAndVisibility( orderedColumnsProperties.properties );

		// const orderSuccessfullyReset = this.resetColumnsOrder( {
		// 	fixedOrder: orderedColumnsProperties.fixedOrder,
		// 	dynamicOrder: orderedColumnsProperties.dynamicOrder
		// } );
		// const widthsAndVisibilitySuccessfullyReset =
		// 	this.resetColumnsWidthsAndVisibility( orderedColumnsProperties.properties );
		// return orderSuccessfullyReset && widthsAndVisibilitySuccessfullyReset;
	}

	resetColumnsOrder( { fixedOrder, dynamicOrder } ) {
		const columns = [];
		[ fixedOrder, dynamicOrder ].forEach( ( order, index ) => {
			const isFixed = index <= 0;
			if ( !Validator.isArray( order, true ) ) {
				return;
			}
			order.forEach( id => {
				const columnId = Number( id );
				if ( !Validator.isPositiveInteger( columnId ) ) {
					return;
				}
				columns.push( { idc: columnId, fix: isFixed } );
			} );
		} );
		if ( !Validator.isArray( columns, true ) ) {
			return false;
		}
		this.restoreColumnOrder( { cols: columns } );
		return true;

		// if ( !Validator.isArray( orderedColumns, true ) ) {
		// 	return false;
		// }
		// let amountOfMovedColumns = 0;
		// for ( let position = 0; position < orderedColumns.length; position++ ) {
		// 	const columnMoved = this.moveColumnToPosition( {
		// 		columnId: orderedColumns[ position ],
		// 		position: position,
		// 		alsoMoveInBody: true
		// 	} );
		// 	if ( !columnMoved ) {
		// 		continue;
		// 	}
		// 	amountOfMovedColumns++;
		// }
		// return amountOfMovedColumns == orderedColumns.length;
	}

	resetColumnsWidthsAndVisibility( columnsProperties ) {
		if ( !Validator.isObject( columnsProperties ) ) {
			return false;
		}
		let allDescribedColumns = Object.entries( columnsProperties );
		if ( !Validator.isIterable( allDescribedColumns ) ) {
			return false;
		}
		allDescribedColumns = [ ...allDescribedColumns ];
		let successfullyProcessedColumns = 0;
		for ( let describedColumn of Object.entries( columnsProperties ) ) {
			if ( !this.resetColumnWidthAndVisibility( describedColumn ) ) {
				continue;
			}
			successfullyProcessedColumns++;
		}
		return successfullyProcessedColumns == allDescribedColumns.length;
	}

	resetColumnWidthAndVisibility( describedColumn ) {
		if ( !Validator.isArray( describedColumn ) ||
			describedColumn.length < 2 ) {
			return false;
		}
		const columnId = Number( describedColumn[ 0 ] );
		if ( !Validator.isPositiveInteger( columnId ) ) {
			return false;
		}
		const properties = describedColumn[ 1 ];
		if ( !Validator.isObject( properties ) ) {
			return false;
		}
		let atLeastSomethingSet = false;
		if ( Validator.isBoolean( properties.isVisible ) ) {
			atLeastSomethingSet = true;
			this.set_vis( { idc: columnId, vis: properties.isVisible } );
		}
		if ( Validator.isBoolean( properties.isAvailable ) ) {
			atLeastSomethingSet = true;
			this.set_avl( { idc: columnId, avl: properties.isAvailable } );
		}
		if ( Validator.isPositiveInteger( properties.width ) ) {
			atLeastSomethingSet = true;
			this.set_width( { idc: columnId, width: properties.width } );
		}
		return atLeastSomethingSet;
	}

}
