import AbstractEditable from "./AbstractEditable";
import Validator from "../../../../utils/Validator";

/**
 * a class that holds all the information to identify an editable data cell
 */
export default class EditTarget extends AbstractEditable {

    /**
     * constructs a new instance
     * @param {AbstractEditable} _ee the target editable element
     * @param {String | Number} _cid the column ID
     * @param {String | Number} _rid the row ID
     * @param {String} _idi the input ID
     */
    constructor(_ee, _cid, _rid, _idi) {
        super('widgets.xtw.editing.EditTarget');
        this._editable = _ee;
        this._cid = _cid;
        this._rid = _rid;
        this._idi = _idi;
    }

    /**
     * creates a new instance
     * @param {AbstractEditable} ee the target editable element
     * @param {String | Number} cid the column ID
     * @param {String | Number} rid the row ID
     * @param {String} iid the input ID
     * @returns {EditTarget | null} the created instance of null if an invalid editable element was given
     */
    static createInstance(ee, cid, rid, iid) {
        if ( !(ee instanceof AbstractEditable) ) {
            return null;
        }
        if ( !Validator.isStringOrNumber(cid) || !Validator.isStringOrNumber(rid) || !Validator.isString(iid) ) {
            return null;
        }
        return new EditTarget(ee, cid, rid, iid);
    }

    /**
     * destroys this instance
     * @override
     */
    doDestroy() {
        delete this._idi;
        delete this._rid;
        delete this._cid;
        delete this._editable;
        super.doDestroy();
    }

    /**
     * @inheritdoc
     * @override
     * @returns {Boolean}
     */
    get alive() {
        return super.alive && this.editable.alive;
    }

    /**
     * @returns {AbstractEditable} the target editable
     */
    get editable() {
        return this._editable;
    }

    /**
     * @returns {String | Number} the column ID
     */
    get columnId() {
        return this._cid;
    }

    /**
     * @returns {String | Number} the row ID
     */
    get rowId() {
        return this._rid;
    }

    /**
     * @returns {String} iid the input ID
     */
    get inputId() {
        return this._idi;
    }

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
    get insertMode() {
        return this.editable.insertMode;
    }

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
    get droppedDown() {
        return this.editable.droppedDown;
    }

    /**
     * compares two instances for equality
     * @param {EditTarget} et the instance to compare with
     * @returns {Boolean} true if this instance equals to the given one; false otherwise
     */
    equals(et) {
        if ( et instanceof EditTarget ) {
            return (this.columnId === et.columnId) && (this.rowId === et.rowId) && (this.inputId === et.inputId);
        }
        return false;
    }

    /**
     * @override
     */
    lock() {
        this.editable.lock();
        super.lock();
    }

    /**
     * @override
     */
    release() {
        this.editable.release();
        super.release();
    }

    /**
     * @inheritdoc
     * @override
     * @param {Number} lc 
     */
    restoreLock(lc) {
        this.editable.restoreLock(lc);
        super.restoreLock(lc);
    }

    /**
     * @override
     */
    setFocus() {
        this.trace('Forwarding focus to editable.');
        this.editable.setFocus();
    }

    /**
     * @inheritdoc
     * @override
     */
    forceBlur(unlock = false) {
        this.editable.forceBlur(!!unlock);
    }

    /**
     * @inheritdoc
     * @override
     */
    flush() {
        this.editable.flush();
    }

    /**
     * @override
     */
    getType() {
        return this.editable.getType();
    }

    /**
     * @override
     */
    register() {
        return this.editable.register();
    }

    /**
     * @override
     */
    render() {
        this.editable.render();
    }

    /**
     * @override
     */
    destroySelfAndRestoreCell() {
        return this.editable.destroySelfAndRestoreCell();
    }

    /**
     * @inheritdoc
     * @override
     * @param {Boolean} horz 
     * @param {Boolean} upleft 
     * @param {KeyboardEvent} ke
     * @returns {Boolean}
     */
    needsArrowKey(horz, upleft, ke) {
        return this.editable.needsArrowKey(horz, upleft, ke);
    }

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} editingAllowed 
	 * @returns {Boolean}
	 */
    setEditingPermission( editingAllowed ) {
        return this.editable.setEditingPermission(editingAllowed);
    }

    /**
     * @inheritdoc
     * @override
     * @param {String} ovr 
     */
    setOvrOrgValue(ovr) {
        this.editable.setOvrOrgValue(ovr);
    }

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} open 
	 * @param {String} wdgId 
	 */
    setDropdownOpenState(open, wdgId) {
        this.editable.setDropdownOpenState(open, wdgId);
    }

    /**
     * @inheritdoc
     * @override
     * @param {*} args 
     */
    syncInputContentWithDropdown(args) {
        this.editable.syncInputContentWithDropdown(args);
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutContentChange() {
        return this.editable.informAboutContentChange();
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutCancel(parameters = {}, blockScreenRequest = false) {
        return this.editable.informAboutCancel(parameters, blockScreenRequest);
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutSave( parameters = {}, blockScreenRequest = false ) {
        return this.editable.informAboutSave(parameters, blockScreenRequest);
    }

    /**
     * @inheritdoc
     * @override
     */
    informAboutFullSave( parameters = {}, blockScreenRequest = false ) {
        return this.editable.informAboutFullSave(parameters, blockScreenRequest);
    }

    /**
     * @inheritdoc
     * @override
     */
    createEditRequest() {
		return this.editable.createEditRequest();
	}
}