import LoggingBase from "../../../base/loggingbase";

/**
 * table body part interface
 */
export default class IBody extends LoggingBase {
    /**
     * constructs a new instance
     * @param {string} ln logger name
     */
    constructor(ln) {
        super(ln);
    }

    /**
     * scrolls the table view
     * @param {boolean} dist scrolling distance: true: a page; false: a single line
     * @param {boolean} dir scrolling direction: true: upwards; false: downwards
     */
    scroll(dist, dir) {
        // abstract
    }

    /**
     * handles relevant "arrow" key events
	 * @param {Boolean} press flag whether the key pressed ("keydown") or released ("keyup")
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     * @param {Boolean} arrow "arrow key" flag
     * @param {Boolean} page "page up/down" flag
     * @param {Boolean} dir direction flag
     */
    onArrowKey(press, e, shift, ctrl, arrow, page, dir) {
        // abstract
    }

    /**
     * handles "[Tab]" key events
	 * @param {Boolean} press flag whether the key pressed ("keydown") or released ("keyup")
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     */
    onTabKey(press, e, shift, ctrl) {
        // abstract
    }
}