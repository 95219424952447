import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
import DomEventHelper from '../../../../utils/DomEventHelper';
import InputField from './InputField';
import Validator from '../../../../utils/Validator';
import IListenerMgr from '../../../../internal/IListenerMgr';

const CALLBACK_PREFIX = 'Textarea';

export default class TextareaKeyHandlingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	/**
	 * adds listeners to the text area element
	 * @param {HTMLElement} textareaElement the text area DOM element
	 * @param {IListenerMgr} listener manager
	 * @returns 
	 */
	addTextareaListeners( textareaElement, manager ) {
		if ( !( textareaElement instanceof HTMLElement ) ) {
			textareaElement = this.textarea;
		}
		if ( !( textareaElement instanceof HTMLElement ) ) {
			return false;
		}
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onTextareaClick",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const keydownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onTextareaKeyDown",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onTextareaKeyUp",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const keypressListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keypress",
			functionName: "onTextareaKeyPress",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const blurListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "blur",
			functionName: "onTextareaBlur",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const mousewheelListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousewheel",
			functionName: "onTextareaScroll",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const contextmenuListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "contextmenu",
			functionName: "onTextareaContextMenu",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const pasteListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "paste",
			functionName: "onTextareaPaste",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		const inputListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "input",
			functionName: "onTextareaInput",
			callBackPrefix: CALLBACK_PREFIX,
			element: textareaElement,
			useCapture: false,
			manager: manager
		} );
		return clickListenerSuccessfullyAdded && keydownListenerSuccessfullyAdded &&
			keyupListenerSuccessfullyAdded && keypressListenerSuccessfullyAdded &&
			blurListenerSuccessfullyAdded && mousewheelListenerSuccessfullyAdded &&
			contextmenuListenerSuccessfullyAdded && pasteListenerSuccessfullyAdded &&
			inputListenerSuccessfullyAdded;
	}

	onTextareaClick( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
		}
	}

	onTextareaKeyDown( domEvent ) {
		if ( DomEventHelper.isCtrlEvent(domEvent) ) {
			return false;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
		}
		if ( DomEventHelper.keyIs( domEvent, "Tab" ) ) {
			return this.handleTab( domEvent );
		}
		if ( DomEventHelper.isContentChangingKey( domEvent ) ) {
			return this.handleContentChangingKeyDown( domEvent );
		}
	}

	onTextareaKeyUp( domEvent ) {
		if ( DomEventHelper.isCtrlEvent(domEvent) ) {
			return false;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
		}
		if ( DomEventHelper.isContentChangingKey( domEvent ) ) {
			return this.handleContentChangingKeyUp( domEvent );
		}
		if ( DomEventHelper.keyIs( domEvent, "Escape" ) ) {
			return this.handleEscape( domEvent );
		}
	}

	onTextareaKeyPress( domEvent ) {
		if ( DomEventHelper.isCtrlEvent(domEvent) ) {
			return false;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
		}
	}

	onTextareaBlur( domEvent ) {
		this.informAboutContentChange();
		return this.destroySelfAndRestoreCell();
	}

	onTextareaScroll( domEvent ) {
		this.informAboutContentChange();
		return this.destroySelfAndRestoreCell();
	}

	onTextareaContextMenu( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.inputId = this.textarea.id;
		}
	}

	onTextareaPaste( domEvent ) {
		// TODO: check whether this is allowed & enter edit mode!
		const pastedContent = DomEventHelper.getClipboardDataTransferText( domEvent );
		if ( !Validator.isString( pastedContent ) ) {
			return false;
		}
		if ( this.allowedInsertionLength < pastedContent.length ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		return true;
	}

	onTextareaInput( domEvent ) {
		this.updateCounterContent();
	}

	onButtonContainerClick( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		return this.handleMaximizeRequest( domEvent );
	}

	handleContentChangingKeyDown( domEvent ) {
		if ( DomEventHelper.isContentKeepingControlEvent( domEvent ) ) {
			return true;
		}
		if ( !this.canBeEdited || !this.editingAllowed ||
			this._cce.eventSurpassesMaximumCharacterCount( domEvent ) ) {
			if ( domEvent instanceof Event ) {
				domEvent.preventDefault();
			}
			return false;
		}
		return true;
	}

	handleContentChangingKeyUp( domEvent ) {
		if ( DomEventHelper.isSelectAllEvent( domEvent ) ) {
			if ( this.textarea instanceof HTMLElement ) {
				this.textarea.select();
			}
			return true;
		}
		if ( DomEventHelper.isContentKeepingControlEvent( domEvent ) ) {
			return true;
		}
		if ( !this.canBeEdited || !this.editingAllowed ) {
			if ( domEvent instanceof Event ) {
				domEvent.preventDefault();
			}
			return false;
		}
		const row = this.row;
		if ( Validator.isObject( row ) && !row.edited ) {
			this.informAboutEditing();
		}
		this.updateCounterContent();
		return true;
	}

	handleMinimizeRequest( domEvent ) {
		const currentValue = this.inputValue;
		const selectionStart = this.selectionStart;
		const selectionEnd = this.selectionEnd;
		const selectionDirection = this.selectionDirection;
		const cell = this.cell;
		this.destroySelf();
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.createAndFocusInputField ) ) {
			return false;
		}
		cell.createAndFocusInputField();
		if ( !( cell.inputField instanceof InputField ) ||
			!( cell.inputField.input instanceof HTMLElement ) ) {
			return false;
		}
		if ( !Validator.isString( currentValue ) ) {
			return true;
		}
		cell.inputField.input.value = currentValue;
		if ( cell.inputField.originalValue != currentValue ) {
			cell.inputField.dirty = true;
			cell.inputField.register();
		}
		cell.inputField.setSelection( selectionStart, selectionEnd, selectionDirection );
		return true;
	}

	handleEscape( domEvent ) {
		this.handleMaximizeRequest(domEvent);
	}

	handleTab( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const cell = this.cell;
		this.informAboutContentChange();
		this.destroySelfAndRestoreCell();
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputTab ) ) {
			return false;
		}
		return cell.onInputTab( domEvent );
	}

}

export { CALLBACK_PREFIX };