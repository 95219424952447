import PSA from '../../psa';

/**
 * class MnuPopWdg - client side implementation of popup menu support widget
 */
export default class MnuPopWdg {
	
	/**
	 * constructs a new instance
	 * @param {Object} properties 
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// bind RAP related methods
		this._psa.bindAll(this, [ "onReady", "onRender" ]);
		this.ready = false;
		// initialize the widget
		this.parent = rap.getObject(properties.parent);
		const cwd = this.parent.getData("pisasales.CSTPRP.CWD");
		this.refWdg = cwd.ref || null;
		this.mnuDsc = cwd.mnu || null;
		this.orgRct = cwd.rct || null;
		this.popMnu = null;
		// activate "render" event
		rap.on("render", this.onRender);
	}

	/**
	 * called by the framework to destroy the widget
	 */
	destroy() {
		this.ready = false;
		if ( this.popMnu ) {
			const mnu = this.popMnu;
			this.popMnu = null;
			mnu.destroy();
		}
		delete this.popMnu;
		delete this.refWdg;
		delete this.mnuDsc;
		delete this.parent;
	}

	onRender() {
		rap.off("render", this.onRender);			// just once!
		this.onReady();
	}

	onReady() {
		this.ready = true;
		if ( this.refWdg && this.mnuDsc ) {
			// try to show the menu
			const wdg = rwt.remote.ObjectRegistry.getObject(this.refWdg);
			if ( wdg && wdg._element ) {
				const elm = wdg._element;
				const dsc = this.mnuDsc;
				if ( dsc && dsc.id && dsc.items && dsc.items.length && (dsc.items.length > 0) ) {
					// that's (hopefully) a menu structure
					const idm = dsc.id || 0;
					const items = dsc.items;
					this.popMnu = this._psa.getMnuMgr().createMenu(idm, items);
					if ( this.popMnu ) {
						// show the menu immediately
						const or = this.orgRct;
						const rect = or ? new DOMRect(or.x, or.y, or.w, or.h) : null;
						this._psa.getMnuMgr().showMenu(this.popMnu, { element: elm, rect: rect }, this, true, false);
					}
				}
			}
		}
		if ( !this.popMnu ) {
			// failed to create the menu...
			this.onMenuClose();
		}
	}

	/**
	 * called by the menu manager if a menu items was clicked
	 * @param {Number} id ID of the menu item
	 */
	onMenuItem(id) {
		if ( this.ready ) {
			const par = {};
			par.id = id || '';
			this._psa.setBscRqu();
			this._nfySrv("mnuItm", par);
		}
	}

	/**
	 * called by the menu manager if a menu was closed, that was triggered by this instance
	 */
	onMenuClose() {
		if ( this.ready ) {
			const par = {};
			this._nfySrv("mnuCls", par);
		}
	}

	/**
	 * called to update a menu item
	 * @param {Object} args arguments
	 */
	updMnu(args) {
		if ( this.popMnu ) {
			const id = args.id || 0;
			if ( id ) {
				// update the menu item
				this.popMnu.updMnuItm(id, args);
			}
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv(code, par) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify("MNU_POP_NFY", param);
		}
	}

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget MnuPopWdg.');
		rap.registerTypeHandler("psawidget.MnuPopWdg", {
			factory : function(properties) {
				return new MnuPopWdg(properties);
			},
			destructor: "destroy",
			properties: [  ],
			methods: [ "updMnu" ],
			events: [ "MNU_POP_NFY"]
		});
	}
}

console.debug('widgets/menu/MnuPopWdg.js loaded.');